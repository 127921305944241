import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const GetDocumentType = () => {
    return service.get(ApiUrl.DocumentType).then(response => { return response }).catch(error => { throw error });
}

export const SearchDocument = (data) => {
    const params = new URLSearchParams();
    params.append('pageIndex', data.pageIndex);
    params.append('pageSize', data.pageSize);
    data.name && params.append('name', data.name);
    data.documentTypeId && params.append('documentTypeId', data.documentTypeId);
    data.commune && params.append('commune', data.commune);
    data.year && params.append('year', data.year);
    return service.get(ApiUrl.SearchDocument, params).then(response => { return response }).catch(error => { throw error });
}
export const GetLockUpDistrict = () => {
    return service.get(ApiUrl.GetCmsLookupDistrict).then(response => { return response }).catch(error => { throw error });
}
export const GetLookupCommune = () => {
    return service.get(ApiUrl.GetLookupCommune).then(response => { return response }).catch(error => { throw error });
}
export const GetDocumentByPlanningId = (id) => {
    const params = new URLSearchParams();
    params.append('planningId', id);
    return service.get(ApiUrl.GetDocumentByPlanningId, params).then(response => { return response }).catch(error => { throw error });
}
export const GetChildrenDocument = (planningId, parentId) => {
    const params = new URLSearchParams();
    params.append('planningId', planningId);
    params.append('parentId', parentId);
    return service.get(ApiUrl.GetChildrenDocument, params).then(response => { return response }).catch(error => { throw error });
}
export const GetYearStatement = () => {
    return service.get(ApiUrl.GetYearStatement).then(response => { return response }).catch(error => { throw error });
}
export const DownloadFile = (id) => {
    const params = new URLSearchParams();
    params.set('id', id);
    return service.getBinary(ApiUrl.DownloadFile, params).then(response => { return response }).catch(error => { throw error });
}