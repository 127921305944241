import React, { useState, useEffect } from 'react';
import './planning-type.scss';

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import PaginationView from '../../../../components/pagination/pagination.view.jsx';
import ThumbnailPreview from './child/thumbnail-preview.view';

import * as statementActions from '../../../../redux/store/statement/statement.store';
import * as config from '../../../../utils/configuration';
import * as appActions from "../../../../core/app.store";
import * as homePageStore from "../../../../redux/store/home-page/home-page.store";
import PageUrls from '../../../../common/url-collect';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

function PlanningByTypeView(props) {
  const { isLoading, showLoading, params, setDataSelectBoxSearch, selected, is_called_from_mobile } = props;
  const [planningDetail, setPlanningDetail] = useState();
  useEffect(() => {

    const key = new URLSearchParams(params.location.search).get("key");
    const search = new URLSearchParams(params.location.search).get("search");
    const searchName = new URLSearchParams(params.location.search).get("searchName");
    key && !search && getPlanningSearchByIdName(key);
    search && getPlanningSearchByIdName(null, 1, search);
    searchName && getPlanningSearchByIdName(null, 1, null, searchName);
  }, [params.location.search])
  useEffect(() => {
    selected && getPlanningSearchByIdName(null, 1, selected.id);
  }, [selected])

  const getPlanningSearchByIdName = (PlanningTypeById, pageIndex = 1, planningId, name, pageSize = 9) => {
    showLoading(true);
    return homePageStore.GetPlanningSearchByIdName(PlanningTypeById, pageIndex, planningId, name, pageSize).then(res => {
      // if (res && res.content) {
      setPlanningDetail(res && res.content ? res.content : {});
      let planning = res.content;
      if (!PlanningTypeById || !selected) {
        setDataSelectBoxSearch(planning && planning.items && planning.items.length > 0 ? planning.items.map(item => { return { ...item, value: item.id, label: item.name } }) : []);
      } else {
        getAllPlanningByTypeId(PlanningTypeById);
      }
      // }
      showLoading(false)
    }).catch(err => showLoading(false))
  }

  const getAllPlanningByTypeId = (id) => {
    statementActions.GetAllPlanningByTypeId(id).then(res => res &&
      res.content &&
      res.content.length > 0 ?
      setDataSelectBoxSearch(res.content.map(item => { return { ...item, label: item.name, value: item.id } })) : []).catch(err => { })
  }


  return (
    <div className="planning-by-type-container position-relative">
      <div className="row">
        {planningDetail && planningDetail.items && planningDetail.items.length > 0 ? planningDetail.items.map((item, index) => (
          <ThumbnailPreview
            key={index}
            backgroundUrl={item.photo && item.photo !== 'null' ? config.APIUrlDefault + item.photo : require('../../../../assets/image/img-default.png')}
            previewName={item.name}
            urlParams={`${!is_called_from_mobile ? PageUrls.PlanningMapViewDetail : PageUrls.PlanningMapViewDetailMobile}${item.id}`}
          />
        )) : (
            <div className="col-12 text-center text-danger mb-3 mt-5">
              <FontAwesomeIcon
                icon={faExclamationTriangle}
                className="mr-2"
              />{" "}
            Không có quy hoạch nào
            </div>
          )}
      </div>
      <div className="pagination-container list-footer">
        {
          planningDetail && planningDetail.pageCount > 0 && (
            <div className={`row ${planningDetail.pageCount === 1 ? 'justify-content-center' : 'justify-content-between'}`}>
              <div className="text-result">
                <span className="ml-2">Có {planningDetail.totalItemCount} kết quả</span>
              </div>
              {
                planningDetail.pageCount > 1 && (<PaginationView totalItems={planningDetail.totalItemCount}
                  currentIndex={planningDetail.pageIndex}
                  pageSize={planningDetail.pageSize} onClick={getPlanningSearchByIdName} id={new URLSearchParams(params.location.search).get("key")} />)
              }
            </div>
          )
        }
      </div>
    </div>
  )
}
const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
  is_called_from_mobile: state.embeddedStatus.isEmbedded
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );


export default connect(mapStateToProps, mapDispatchToProps)(PlanningByTypeView);