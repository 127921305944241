import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import * as planningAction from "../../../redux/store/planning/planning.store";
import * as appActions from "../../../core/app.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import {
  NotificationMessageType,
  getUserInfo,
} from "../../../utils/configuration";
import AsyncSelect from 'react-select/async';
import UrlCollect from "../../../common/url-collect";
import "./slider.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SlickArrowLeft from "./slick-arrow-left.view";
import SlickArrowRight from "./slick-arrow-right.view";
import history from "../../../common/history";
import MapView from "../map/map.view";
import { planningIdInfo } from "../../../mock-data/planningIdMock.mock";
var query = null;

function HomeSliderView(props) {
  const { showLoading } = props;

  const settingSlider = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const [slideShow, setSlideShow] = useState([]);
  const [coord, setCoord] = useState()
  const [isOpenNameSearch, setIsOpenNameSearch] = useState(true);
  const [isOpenXySearch, setIsOpenXySearch] = useState(false);
  const [isOpenAddressSearch, setIsOpenAddressSearch] = useState(false);
  const [isOpenLatLngSearch, setIsOpenLatLngSearch] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    latitude: null,
    longitude: null,
    altitude: null,
    accuracy: null,
    altitudeAccuracy: null,
    heading: null,
    speed: null,
  });
  const isLogin = getUserInfo() ? true : false;

  const customStyles = {
    control: () => ({
      border: 'none',
      height: '28px',
    }),
  };

  const onGetCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentPosition(position.coords);
          resolve(position);
        },
        (error) => {
          reject(error);
        }
      );
    });
  };

  const loadOptions = (inputValue, callback) => {
    if (query) {
      clearTimeout(query);
    }

    if (inputValue.length > 2) {
      query = setTimeout(() => {
        // return locationSvc.getLookupAutoComplete(params).then(({ content }) => {
        if (inputValue) {
          if (isOpenNameSearch) {
            const getListProjects = async () => {
              let results = [];
              const res = await planningAction.GetListAll(1, 100, inputValue);
              if (res.content.items && res.content.items.length > 0) {
                results = res.content.items.map(item => {
                  return { ...item, value: item.id, label: item.name };
                })
              }
              if (results.length == 0) {
                ShowNotification(
                  'Vui lòng nhập tên dự án',
                  NotificationMessageType.Warning,
                  'center'
                );
              }
              callback(results);
            };
            getListProjects();
          } else if (isOpenAddressSearch) {
            const getListAddress = async () => {
              let results = [];
              const res = await planningAction.GetLatLngPlace(inputValue);
              if (res.result && res.result.length > 0) {
                results = res.result.map(item => {
                  return { ...item, value: item.id, label: item.name };
                })
              }
              if (results.length == 0) {
                ShowNotification(
                  'Vui lòng nhập tên dự án',
                  NotificationMessageType.Warning,
                  'center'
                );
              }
              callback(results);
            };
            getListAddress();
          }
        }
      }, 800);
    } else {
      callback([]);
    }
  };

  const handleChoose = (input) => {
    if (isOpenNameSearch) {
      let url = '';
      if (input.id == planningIdInfo.planningId_KHQHSDD) {
        url = UrlCollect.ktqhsdd;
      } else if (input.id == planningIdInfo.planningId_GT) {
        url = UrlCollect.giao_thong;
      // } else if (input.id == planningIdInfo.planningId_DADTXD) {
      //   url = UrlCollect.ProjectManagement;
      } else {
        url = UrlCollect.PlanningMapViewDetail;
      }
      history.push(url + input.id)
    } else {
      GetPlanningByCoordWgs84(input.location.lng, input.location.lat);
    }
  }

  const handleClickCurrentLocation = () => {
    let currentPositionTemp = currentPosition;

    if (!currentPositionTemp) {
      navigator.geolocation.getCurrentPosition((position) => {
        currentPositionTemp = currentPosition;
      });
    }
    if (currentPositionTemp.latitude && currentPositionTemp.longitude) {
      GetPlanningByCoordWgs84(currentPositionTemp.longitude, currentPositionTemp.latitude);
    }
  }

  const GetPlanningByCoordWgs84 = (lng, lat) => {
    planningAction.GetPlanningByCoordWgs84(lng, lat).then(res => {
      if (res.content === 0) {
        ShowNotification(
          'Không có dự án nào nằm tại vị trí của bạn!',
          NotificationMessageType.Warning
        );
      } else {
        if (res.content == planningIdInfo.planningId_KHQHSDD) {
          history.push(
            `${UrlCollect.ktqhsdd}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
          )
        } else if (res.content == planningIdInfo.planningId_GT) {
          history.push(
            `${UrlCollect.giao_thong}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
          )
        // } else if (res.content == planningIdInfo.planningId_DADTXD) {
        //   history.push(
        //     `${UrlCollect.ProjectManagement}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
        //   )
        } else {
          history.push(
            `${UrlCollect.PlanningMapViewDetail}${res.content}?lat=${lat}&lng=${lng}&searchType=place`
          )
        }
      }
    }).catch(err => { console.log(err) })
  }

  const searchCoordinate = (event) => {

  }

  const pressKey = (event) => {
    if (event.keyCode == 13 && coord) {
      let coordinateList = coord.split(',');
      if (coordinateList && coordinateList.length === 2) {
        let x = parseFloat(coordinateList[0]);
        let y = parseFloat(coordinateList[1]);
        if (isOpenXySearch) {
          planningAction.GetVn2000ToWgs84(x, y).then((coords) => {
            GetPlanningByCoordWgs84(coords[0], coords[1]);
          })
        } else {
          GetPlanningByCoordWgs84(y, x);
        }
      } else {
        ShowNotification(
          'Vui lòng nhập đúng định dạng tọa độ',
          NotificationMessageType.Warning,
          'center'
        );
      }
    }
  }

  useEffect(() => {
    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      onGetSlideShow(),
      onGetCurrentPosition(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
      });
  };

  const onGetSlideShow = () => {
    return new Promise((resolve, reject) => {
      props
        .getSlideShow()
        .then((res) => {
          setSlideShow(res && res.content && res.content.length > 0 ? res.content : []);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };
  const handleChooseSearchType = (type) => {
    if (type === 'isAddress') {
      setIsOpenAddressSearch(true);
      setIsOpenNameSearch(false);
      setIsOpenXySearch(false);
      setIsOpenLatLngSearch(false);
    } else if (type === 'isXy') {
      setIsOpenAddressSearch(false);
      setIsOpenNameSearch(false);
      setIsOpenXySearch(true);
      setIsOpenLatLngSearch(false);
    } else if (type === 'isLatLng') {
      setIsOpenAddressSearch(false);
      setIsOpenNameSearch(false);
      setIsOpenXySearch(false);
      setIsOpenLatLngSearch(true);
    } else {
      setIsOpenAddressSearch(false);
      setIsOpenNameSearch(true);
      setIsOpenXySearch(false);
      setIsOpenLatLngSearch(false);
    }
  }

  const listEntry = [
    {
      name: 'Xin ý kiến cộng đồng',
      url: UrlCollect.PleaseConsultTheCommunity,
      imgUrl: require("../../../assets/icon/xin-y-kien.png"),
    },
    {
      name: 'Công bố quy hoạch',
      url: UrlCollect.PlanningAnnouncement,
      imgUrl: require("../../../assets/icon/cong-bo-qh.png"),
    },
    {
      name: 'Tra cứu quy hoạch',
      url: UrlCollect.SyntheticPlanning,
      imgUrl: require("../../../assets/icon/tra-cuu-qh.png"),
    },
    {
      name: 'Quản lý HS quy hoạch',
      url: UrlCollect.RecordsManager,
      imgUrl: require("../../../assets/icon/quan-ly-hs.png"),
    },
    {
      name: 'Quy hoạch xây dựng',
      url: UrlCollect.SyntheticPlanning,
      imgUrl: require("../../../assets/icon/Quy hoach xay dung.png"),
    },
    {
      name: 'Kế hoạch, quy hoạch sử dụng đất',
      url: `${UrlCollect.ktqhsdd}${planningIdInfo.planningId_KHQHSDD}`,
      imgUrl: require("../../../assets/icon/Ke hoach quy hoach su dung dat.png"),
    },
    {
      name: 'Quy hoạch giao thông',
      url: '/quy-hoach-giao-thong/14',
      url: `${UrlCollect.giao_thong}${planningIdInfo.planningId_GT}`,
      imgUrl: require("../../../assets/icon/Giao thong.png"),
    },
    // {
    //   name: 'Dự án đầu tư xây dựng',
    //   url: '/du-an-dau-tu-xay-dung',
    //   imgUrl: require("../../../assets/icon/Du an dau tu xay dung.png"),
    // },
  ]

  return (
    //<Link to='/tong-hop-quy-hoach'><section id="home-banner" className="banner"></section></Link>    
    <section id="home-banner" className="banner">
      <div className="banner_bg">
        <div className="row banner_content">
          <div className="col-md-6 left_banner">
            <div className="banner_title">
              <p>CƠ SỞ DỮ LIỆU QH XÂY DỰNG</p>
              <p className="pl-5">& QUY HOẠCH ĐÔ THỊ</p>
              <p className="banner_title1">THÀNH PHỐ CAO BẰNG</p>
            </div>
            <div className="search-section">
              <div className="banner_search pb-2">
                <div
                  className={`search_icon ${isOpenNameSearch ? 'active' : ''}`}
                  onClick={() => handleChooseSearchType('isName')}
                >
                  <img className="" src={require('./fi_search.png')} alt="" />
                </div>
                <div
                  className={`search_icon ${isOpenXySearch ? 'active' : ''}`}
                  onClick={() => handleChooseSearchType('isXy')}
                >
                  <img className="" src={require('./vector.png')} alt="" />
                </div>
                <div
                  className={`search_icon ${isOpenLatLngSearch ? 'active' : ''}`}
                  onClick={() => handleChooseSearchType('isLatLng')}
                >
                  <img className="" src={require('./fi_latlng.png')} alt="" />
                </div>
                <div
                  className={`search_icon ${isOpenAddressSearch ? 'active' : ''}`}
                  onClick={() => handleChooseSearchType('isAddress')}
                >
                  <img className="" src={require('./fi_map-pin.png')} alt="" />
                </div>
                <div
                  className={`search_icon`}
                  onClick={() => handleClickCurrentLocation()}
                >
                  <img className="" src={require('./locate-icon.png')} alt="" />
                </div>
              </div>
              <div className="search_input">
                {(isOpenNameSearch || isOpenAddressSearch) ? (
                  <AsyncSelect
                    className='search_input_select'
                    placeholder={isOpenNameSearch ? 'Nhập tên dự án để tìm kiếm' : 'Nhập địa chỉ để tìm kiếm'}
                    isLoading={false}
                    loadOptions={loadOptions}
                    onChange={data => {
                      handleChoose(data);
                    }}
                    components={{ DropdownIndicator: null, LoadingIndicator: null, ClearIndicator: null }}
                    styles={customStyles}
                    noOptionsMessage={() => isOpenNameSearch ? 'Nhập tên dự án để tìm kiếm' : 'Nhập địa chỉ để tìm kiếm'}
                  />
                ) : (
                  <input
                    className="search_input_coord"
                    type="text"
                    placeholder={isOpenXySearch ? "Nhập tọa độ VN2000 (X,Y)" : "Nhập tọa độ WGS84 (Lat, Lng)"}
                    onChange={e => setCoord(e.target.value)}
                    onKeyDown={pressKey}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col-md-6 right_banner">
            <MapView />
          </div>
        </div>
      </div>
      <div className="home-content mt-1">
        <div className="container">
          <div className="content">
            <Slider {...settingSlider}>
              {listEntry.map((item, index) => (
                <div key={index} className="item">
                  <a href={item.url}>
                    <div className="item-block">
                      <img
                        src={item.imgUrl}
                        alt="item1"
                        className="entry_img"
                      />
                      <span>{item.name}</span>
                    </div>
                  </a>
                </div>
              ))}
            </Slider>
          </div>

          {/* <div className="row content">
            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="/y-kien-cong-dong">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/xin-y-kien.png")}
                    alt="item1"
                    className=""
                  />
                  <span >Xin ý kiến cộng đồng</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="/cong-bo-quy-hoach">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/cong-bo-qh.png")}
                    alt="item2"
                    className=""
                  />
                  <span >Công bố quy hoạch</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="tong-hop-quy-hoach">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/tra-cuu-qh.png")}
                    alt="item3"
                    className=""
                  />
                  <span >Tra cứu quy hoạch</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 col-lg-3 item" hidden={!isLogin}>
              <Link to="quan-ly-ho-so">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/quan-ly-hs.png")}
                    alt="item4"
                    className=""
                  />
                  <span >
                    Quản lý HS quy hoạch
                  </span>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="/y-kien-cong-dong">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/Quy hoach xay dung.png")}
                    alt="item1"
                    className=""
                  />
                  <span >Quy hoạch xây dựng</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="/y-kien-cong-dong">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/Ke hoach quy hoach su dung dat.png")}
                    alt="item1"
                    className=""
                  />
                  <span >Kế hoạch, quy hoạch sử dụng đất</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="/y-kien-cong-dong">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/Giao thong.png")}
                    alt="item1"
                    className=""
                  />
                  <span >Quy hoạch giao thông</span>
                </div>
              </Link>
            </div>
            <div className="col-12 col-md-3 col-lg-3 item">
              <Link to="/y-kien-cong-dong">
                <div className="item-block">
                  <img
                    src={require("../../../assets/icon/Du an dau tu xay dung.png")}
                    alt="item1"
                    className=""
                  />
                  <span >Dự án đầu tư xây dựng</span>
                </div>
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
      getSlideShow: homePageStore.GetSlideShow,
    },
    dispatch
  );

export default (connect(mapStateToProps, mapDispatchToProps)(HomeSliderView));
