import React, { useState, useEffect } from 'react';
import { Feature, Map, View } from "ol";
import TileLayer from "ol/layer/Tile";
import { DragPan, MouseWheelZoom, defaults } from 'ol/interaction.js';
import { platformModifierKeyOnly } from 'ol/events/condition.js';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import TileWMS from 'ol/source/TileWMS';
import Point from "ol/geom/Point";
import { Circle as CircleStyle, Fill, Stroke, Style, Icon } from "ol/style";
import Overlay from "ol/Overlay";
import * as config from "../../../utils/configuration";
import * as apiConfig from "../../../api/api-config";
import UrlCollect from "../../../common/url-collect";
import "./home-map.scss";
import history from "../../../common/history";
import {
    VectorImageLayerClassName,
    TileLayerClassName,
    Default_Feature_Style,
    Highlight_Feature_Style,
    Draw_Filter_Feature_Style,
  } from './config';
import Jquery from "jquery";
import { TileLayerClassNameBasedOnStyle } from '../../openlayers-map/config/config';
import { planningIdInfo } from '../../../mock-data/planningIdMock.mock';

function OpenLayerHomeView(props) {
    const [map, setMap] = useState(null);

    useEffect(() => {
        const centerDefault = props.defaultCenter || config.defaultMapCenter;
        const defaultZoom = props.defaultZoom || 9;
        const defaultMaxZoom = props.maxZoom || 24;
        const defaultMinZoom = props.minZoom || 1;
        const defaultListLayer = props.listLayer || [];
        console.log("HOME defaultListLayer",defaultListLayer);
        const defaultExtend = props.extent || [
            config.defaultLeftCornerLng,
            config.defaultLeftCornerLat,
            config.defaultRightCornerLng,
            config.defaultRightCornerLat,
        ];

        const layerDefault = [];
        defaultListLayer.map((layerData) => {
            const layerImage = new TileLayer({
                // visible: layerData.is_check,
                visible: true,
                zIndex: layerData.z_index,
                minZoom: layerData.min_zoom,
                maxZoom: layerData.max_zoom,
                source: new TileWMS({
                    url: layerData.wms,
                    params: {
                        LAYERS: `${config.DataStore}:${layerData.table}`,
                        LayerId: layerData.id,
                        FORMAT: "image/png",
                        VERSION: "1.1.0",
                    },
                    crossOrigin: 'anonymous'
                }),
                className: TileLayerClassNameBasedOnStyle(layerData.id, layerData.style),
            });
            layerDefault.push(layerImage);
        })

        // Init map
        var initialMap = new Map({
            interactions: defaults({ dragPan: false, mouseWheelZoom: false }).extend([
                new DragPan({
                    condition: function (event) {
                        return this.getPointerCount() === 2 || platformModifierKeyOnly(event);
                    },
                }),
                new MouseWheelZoom({
                    condition: platformModifierKeyOnly,
                }),
            ]),
            target: "map-home",
            layers: layerDefault,
            view: new View({
                projection: props.projection,
                center: centerDefault,
                extent: defaultExtend,
                zoom: defaultZoom,
                // minZoom: 10.5,
                // maxZoom: 10.5,
            }),
        });
        setMap(initialMap);
        functionAddEvtHandleClickToDirectDetail(initialMap);

        return () => {
            initialMap.setTarget("map-home");
        };
    }, []);

    const functionAddEvtHandleClickToDirectDetail = (OpenLayersMapObject) => {
        const listLayer = OpenLayersMapObject.getLayers();
        const titleLayerForPick = [];
        listLayer.forEach((layer) => {
            console.log("Each layer",layer.getClassName());
            if (layer.getClassName() === `title-layer-Polygon-${planningIdInfo.HOME_MAP_LAYER_ID}` || layer.getClassName() === `title-layer-${planningIdInfo.HOME_MAP_LAYER_ID}`){
                titleLayerForPick.push(layer);
            }
        })

        console.log("titleLayerForPick",titleLayerForPick);

        OpenLayersMapObject.on("singleclick", function (e) {
            const viewResolution = OpenLayersMapObject.getView().getResolution();
            const viewProjection = OpenLayersMapObject.getView().getProjection();
            const source = titleLayerForPick[0]?.getSource();
            const url = source?.getFeatureInfoUrl(
                e.coordinate,
                viewResolution,
                viewProjection,
                { INFO_FORMAT: "application/json", FEATURE_COUNT: 50 }
            );
            
            // const url = null;
            if (url) {
            Jquery.ajax({
                type: "POST",
                url: url,
                contentType: "application/json; charset=utf-8",
                dataType: "json",
                success: (featureCollection) => {
                if (featureCollection.features.length > 0) {
                    console.log('trong');
                    console.log("clicked coordinate",e.coordinate);
                    window.open(
                        `${UrlCollect.SyntheticPlanning}?lng=${e.coordinate[0]}&lat=${e.coordinate[1]}&searchType=no-search&zoom=13`
                    );
                } else {
                    console.log('ngoài');
                }
                },
            });
        }
        });
    };

    return (
        <>
            <div id="map-home" tabIndex="1" style={{ width: '100%', height: '600px' }}></div>
            {/* <div id="tooltip-openlayer"></div> */}
        </>
    )
}

export default OpenLayerHomeView
