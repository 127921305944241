import React, { Suspense, lazy } from "react";
import "./project-map-with-hools.view.scss";
import OpenlayersMapView from "../openlayers-map/openlayers-map.view";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapDataStore from "../../redux/store/map-data/map-data.store";
import { SetDisplayMaptoolPanel, SetDisplayInfomationPopup } from "../../redux/store/open-layer/map-tools-panels.store";
import * as MapToolDataStore from "../../redux/store/map-tool/map-tool.store";
import PlanningMapViewModel from "../../models/planning-map-view/planning-map-view.model";
import {
  ExtractSelectedBasemap,
  ExtractCenterArray,
  ExtractExtentArray,
  ExtractListLayerArray,
  ExtracLayerListGroup,
  ExtractBasemapList,
  ExtracLayerListGroupFromArrMapData,
  ExtractListLayerArrayFromArrMapData,
} from "../../common/extract-information-from-map-data";
import { planningIdInfo } from "../../mock-data/planningIdMock.mock";
import { fixedDataGroupLayersPlanningView } from "../../mock-data/fixedDataGroupLayers.mock";
import PreviewImgModal from "../../components/left-menu-component/preview-img-modal/preview-img-modal";

//Desktop
const TopRightPanelView = lazy(() => import("../planning-map-view/top-right-panel/top-right-panel.view"));
const RenderFilterInformationPopup = lazy(() => import("../planning-map-view/filter-infomation/filter-infomation.view.jsx"));
const MapToolsPanel = lazy(() => import("../planning-map-view/map-tools-panel/map-tools-panel.view.jsx"));
const ListProject = lazy(() => import("./desktop/left-project/left-project.view"));

// Mobile
const RenderFilterInformationPopupMobile = lazy(() => import("./mobile/filter-infomation/filter-infomation.view"));
const TopRightPanelMobile = lazy(() => import("./mobile/top-right-panel-mobile/top-right-panel-mobile"));
const BottomToggleMobile = lazy(()=> import("../../components/mobile-bottom-toggle/bottom-toggle"));
const BottomPanelMobile = lazy(()=> import("./mobile/bottom-panel-mobile/bottom-panel-mobile"));


class ProjectMapViewWithHooks extends React.Component<
  PlanningMapViewModel.ProjectMapViewProps,
  PlanningMapViewModel.ProjectMapViewState
> {
  constructor(props: PlanningMapViewModel.ProjectMapViewProps) {
    super(props);
    this.state = {
      mapPlanning: null,
      isLeftNavbarHide: true,
      modalHeightStyle: 0,
      isShowMapToolsPanel: false,
      isShowFilterInfomationPopup: false,
      displaySearchLocationBar: false,
      planningModel: null,
      coordinate: "",
      isDoneConstructMap: false,

      fixedDataGroupLayersState: fixedDataGroupLayersPlanningView,

      layerGroups: [],
      layerGroups_KHQHSDD: [],
      layerGroups_GT: [],
      layerGroups_DADTXD: [],

      listLayers : [],
      listLayers_KHQHSDD: [],
      listLayers_GT: [],
      listLayers_DADTXD: [],

      projectcoordinate: null,
    };
    this.openFilterInfomationPopup = this.openFilterInfomationPopup.bind(this);
    this.closeFilterInfomationPopup = this.closeFilterInfomationPopup.bind(this);
  }

  setListLayers_KHQHSDD (data: any) {
    this.setState({
      listLayers_KHQHSDD : data
    })
  }
  
  setListLayers_GT (data: any) {
    this.setState({
      listLayers_GT : data
    })
  }
  
  setListLayers_DADTXD (data: any) {
    this.setState({
      listLayers_DADTXD : data
    })
  }

  setLayerGroups_KHQHSDD (data: any) {
    this.setState({
      layerGroups_KHQHSDD : data
    })
  }

  setLayerGroups_GT (data: any) {
    this.setState({
      layerGroups_GT : data
    })
  }

  setLayerGroups_DADTXD (data: any) {
    this.setState({
      layerGroups_DADTXD : data
    })
  }

  setListLayers (data: any) {
    this.setState({
      listLayers : data
    })
  }

  setFixedDataGroupLayersState(data: any) {
    this.setState ({
      fixedDataGroupLayersState: data
    })
  }

  setIsDoneConstructMap(data: boolean) {
    this.setState ({
      isDoneConstructMap: data
    })
  }

  setDataProject = (data: any) => {
    if (!this.props.isProject) return;

    this.props.setDataProject(data);
  };

  componentDidMount() {
    this.setState({
      modalHeightStyle: window.innerHeight - 100,
    });
    this.props.GetMapDetail(this.props.planningId || 13);
    this.props.SetDisplayMaptoolPanel(false);
    this.props.SetDisplayInfomationPopup(false);
    //point places
    this.props.GetArrMapDetail([planningIdInfo.planningId_POINT_MAP]);

    if(this.props.planningId == planningIdInfo.planningId_KHQHSDD) {
      this.props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");
      this.props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    }else if(this.props.planningId == planningIdInfo.planningId_DADTXD) {
      this.props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
      this.props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    }else if(this.props.planningId == planningIdInfo.planningId_GT) {
      this.props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");
      this.props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
    }else {
      this.props.GetArrMapDetail([planningIdInfo.planningId_DADTXD], "DADTXD");
      this.props.GetArrMapDetail([planningIdInfo.planningId_KHQHSDD], "KHQHSDD");
      this.props.GetArrMapDetail([planningIdInfo.planningId_GT], "GT");
    }
    this.setFixedDataGroupLayersState(fixedDataGroupLayersPlanningView);
  }

  openFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: true,
    });
  }

  closeFilterInfomationPopup() {
    this.setState({
      isShowFilterInfomationPopup: false,
    });
  }

  setPlanningMap = (_planningModel: any) => {
    this.setState({ planningModel: _planningModel });
  };

  componentDidUpdate(prevProps: Readonly<PlanningMapViewModel.ProjectMapViewProps>, prevState: Readonly<PlanningMapViewModel.ProjectMapViewState>, snapshot?: any): void {
    if( this.props.mapData != prevProps.mapData) {
      this.props.SetDataToggleLayer(ExtracLayerListGroup(this.props.mapData));
    }

    if((this.props.arrMapData !== prevProps.arrMapData)){
      this.setState({
        layerGroups : ExtracLayerListGroupFromArrMapData(this.props.arrMapData)
      })
    }
    
    if (( this.props.arrMapData_KHQHSDD !== prevProps.arrMapData_KHQHSDD ) ||
        ( this.props.arrMapData_GT !== prevProps.arrMapData_GT ) ||
        ( this.props.arrMapData_DADTXD !== prevProps.arrMapData_DADTXD ) ||
        ( this.state.isDoneConstructMap !== prevState.isDoneConstructMap )
       ){
        if( this.state.isDoneConstructMap && this.props.arrMapData_KHQHSDD && this.props.arrMapData_KHQHSDD.length > 0 ){
          console.log("this.props.arrMapData_KHQHSDD changed",this.props.arrMapData_KHQHSDD );
          this.setListLayers_KHQHSDD( ExtractListLayerArrayFromArrMapData( this.props.arrMapData_KHQHSDD ));
          this.setLayerGroups_KHQHSDD( ExtracLayerListGroupFromArrMapData( this.props.arrMapData_KHQHSDD ));
        }
        if( this.state.isDoneConstructMap && this.props.arrMapData_GT && this.props.arrMapData_GT.length > 0 ){
          console.log( "this.props.arrMapData_GT changed",this.props.arrMapData_GT );
          this.setListLayers_GT( ExtractListLayerArrayFromArrMapData( this.props.arrMapData_GT ));
          this.setLayerGroups_GT( ExtracLayerListGroupFromArrMapData( this.props.arrMapData_GT ));
        }
        if( this.state.isDoneConstructMap && this.props.arrMapData_DADTXD && this.props.arrMapData_DADTXD.length > 0 ){
          console.log( "this.props.arrMapData_DADTXD changed",this.props.arrMapData_DADTXD );
          this.setListLayers_DADTXD( ExtractListLayerArrayFromArrMapData( this.props.arrMapData_DADTXD ));
          this.setLayerGroups_DADTXD( ExtracLayerListGroupFromArrMapData( this.props.arrMapData_DADTXD ));
        }
    }

    if (( this.state.listLayers_DADTXD !== prevState.listLayers_DADTXD ) ||
        ( this.state.listLayers_GT !== prevState.listLayers_GT ) ||
        ( this.state.listLayers_KHQHSDD !== prevState.listLayers_KHQHSDD ) ||
        ( this.state.isDoneConstructMap !== prevState.isDoneConstructMap ) ||
        ( this.props.arrMapData !== prevProps.arrMapData ) ||
        ( this.props.mapData !== prevProps.mapData )
       ){
        if( this.state.isDoneConstructMap &&
          (
            // Điều kiện như này để đảm bảo chỉ chạy cái if này duy nhất 1 lần.
            (
              (
                this.props.planningId == planningIdInfo.planningId_KHQHSDD || 
                this.props.planningId == planningIdInfo.planningId_DADTXD || 
                this.props.planningId == planningIdInfo.planningId_GT 
              ) 
              &&
              (
                (this.state.listLayers_KHQHSDD.length > 0 &&  this.state.listLayers_GT.length > 0 && this.state.listLayers_DADTXD.length == 0 ) ||
                (this.state.listLayers_KHQHSDD.length == 0 &&  this.state.listLayers_GT.length > 0 && this.state.listLayers_DADTXD.length > 0 ) ||
                (this.state.listLayers_KHQHSDD.length > 0 &&  this.state.listLayers_GT.length == 0 && this.state.listLayers_DADTXD.length > 0 )
              )
            ) 
            ||
            (
              (
                this.props.planningId != planningIdInfo.planningId_KHQHSDD && 
                this.props.planningId != planningIdInfo.planningId_GT && 
                this.props.planningId != planningIdInfo.planningId_DADTXD 
              ) &&
              (
                this.state.listLayers_KHQHSDD.length > 0 &&  this.state.listLayers_GT.length > 0 && this.state.listLayers_DADTXD.length > 0
              )
            )
          )
         ) {
          this.setListLayers( [
            // ...this.state.listLayers,
            ...ExtractListLayerArray(this.props.mapData),
            ...ExtractListLayerArrayFromArrMapData(this.props.arrMapData),
            ...this.state.listLayers_KHQHSDD,
            ...this.state.listLayers_GT,
            ...this.state.listLayers_DADTXD,
          ] );
        }
    }
  }

  componentWillUnmount () {
    this.props.SetDisplayMaptoolPanel(false);
    this.props.SetDisplayInfomationPopup(false);
  }

  render() {

    return (
      <div className="project-map-view-container position-relative">
        {
          this.props.isDesktopOrLaptop && 
          <Suspense fallback={<div>Page is Loading, please wait...</div>}>
            <TopRightPanelView
              baseMapList={ExtractBasemapList(this.props.mapData)}
              planningId={this.props.isProject ? this.props.planningId : this.props.match.params.id}
              setPlanningModelInMap={this.setPlanningMap}
            />

            {/* map tool panel control */}

            <div className="position-absolute map-tools-container">
              <div className="position-relative">
                <RenderFilterInformationPopup
                  listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
                  listLayer={ExtractListLayerArray(this.props.mapData)}
                />
              </div>
              <MapToolsPanel
                openFIlterPopup={this.openFilterInfomationPopup}
                map={this.state.mapPlanning}
              />
            </div>

            <ListProject
              setProjectcoordinate={(data: any) => this.setState({ projectcoordinate: data })}
              isDoneConstructMap={this.state.isDoneConstructMap}
            />

          </Suspense>
        }
        {
          this.props.isTabletOrMobile && 
          <Suspense fallback={<div>Page is Loading, please wait...</div>}>
            <TopRightPanelMobile
              baseMapList={ExtractBasemapList(this.props.mapData)}
              planningId={this.props.planningId}
              setPlanningModelInMap={this.setPlanningMap}
            />

            <BottomToggleMobile/>

            <BottomPanelMobile
              planningId={this.props.planningId}
              //custom
              setProjectcoordinate={(data: any) => this.setState({ projectcoordinate: data })}
              isDoneConstructMap={this.state.isDoneConstructMap}
            />
            
            <RenderFilterInformationPopupMobile
              listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
              listLayer={ExtractListLayerArray(this.props.mapData)}
            />
          </Suspense>
        }
        
        {this.props.hasLoadedMapData && this.props.mapData.name && (
          <div className={this.props.isProject ? 'open-layers-map-view-container_' : 'open-layers-map-view-container'}>
            <OpenlayersMapView
              defaultBaseMapUrl={ExtractSelectedBasemap(this.props.mapData)}
              defaultCenter={ExtractCenterArray(this.props.mapData)}
              defaultZoom={this.props.mapData.zoom}
              projection={this.props.mapData.projection}
              maxZoom={this.props.mapData.max_zoom}
              minZoom={this.props.mapData.min_zoom}
              extent={ExtractExtentArray(this.props.mapData)}
              listLayer={this.state.listLayers}
              planningModel={this.state.planningModel}
              listGroupLayer={ExtracLayerListGroup(this.props.mapData)}
              coordinate={this.props.location}
              setIsDoneConstructMap={(data: any) => this.setIsDoneConstructMap(data)}
              projectcoordinate={this.props.isProject ? this.state.projectcoordinate : null}
              isProject={this.props.isProject}
              setDataProject={this.setDataProject}

              //props for assissive chip in map view
              layerGroupsPlace={this.state.layerGroups}
              layerGroups_KHQHSDD={this.state.layerGroups_KHQHSDD}
              layerGroups_GT={this.state.layerGroups_GT}
              layerGroups_DADTXD={this.state.layerGroups_DADTXD}
              fixedDataGroupLayers={this.state.fixedDataGroupLayersState}
              setFixedDataGroupLayers={(data: any) => this.setFixedDataGroupLayersState(data)}
              idPlanningToHide={this.props.planningId}
            />
          </div>
        )}

        {this.props.hasLoadedMapData && !this.props.mapData.name && (
          <div
            className="modal d-block"
            id="modalNotHaveDataToDisplay"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Dữ liệu không có sẵn để hiển thị
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  Lỗi: Bản đồ của dự án chưa được khởi tạo, vui lòng liên hệ với
                  Admin
                </div>
                <div className="modal-footer text-center">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() =>
                      document
                        .getElementById("modalNotHaveDataToDisplay")
                        ?.classList.remove("d-block")
                    }
                  >
                    Đóng
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <PreviewImgModal />
      </div> 
    );
  }
}

const mapStateToProps = (state: any) => ({
  hasLoadedMapData: state.mapData.hasLoadingData,
  mapData: state.mapData.mapData,
  planningRelationShips: state.mapData.planningRelationShips,
  arrMapData: state.mapData.arrMapData,
  arrMapData_KHQHSDD: state.mapData.arrMapDataKHQHSDD, 
  arrMapData_GT: state.mapData.arrMapDataGT,
  arrMapData_DADTXD: state.mapData.arrMapDataDADTXD,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      GetMapDetail: MapDataStore.GetMapDetailByPlanningId,
      GetArrMapDetail : MapDataStore.GetArrMapDetailByPlanningId,
      SetDataToggleLayer: MapToolDataStore.SetDataToggleLayer,
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      SetDisplayInfomationPopup: SetDisplayInfomationPopup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ProjectMapViewWithHooks);
