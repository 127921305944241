import MapDataModels from "../models/map-data-model-b/map-data-model";
import LayerSettingsModels from "../models/map-data-model-b/layer-setting-models";
import LayerCategoryModels from "../models/map-data-model-b/layer-category-models";
import BaseMapModels from "../models/map-data-model-b/base-map-models";
import { planningIdInfo } from "../mock-data/planningIdMock.mock";

export const ExtracAllLayersArrays = (
  mapData: MapDataModels.MapDataModels
): LayerSettingsModels.LayerSettingsModel[] => {
  let result: LayerSettingsModels.LayerSettingsModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map != "MAP") {
      mapSetting.layer_categories.map((layerCategories) => {
        result = [...result, ...layerCategories.layer_settings];
      });
      return;
    }
  });
  return result;
};

export const ExtracLayerListGroup = (
  mapData: MapDataModels.MapDataModels
): LayerCategoryModels.LayerCategoryModel[] => {
  let result: LayerCategoryModels.LayerCategoryModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map !== "MAP") {
      result = mapSetting.layer_categories;
      return;
    }
  });
  return result;
};

export const ExtracLayerListGroupFromArrMapData = (
  arrMapData: MapDataModels.MapDataModels[]
): any => {
  let result: LayerCategoryModels.LayerCategoryModel[] = [];
  console.log("ExtracLayerListGroupFromArrMapData run",arrMapData);
  
  
  arrMapData.forEach((mapData) => {
    //lớp trên cùng để chọn thì ko hiện chip
    if(mapData.planing_id != `${planningIdInfo.planningId_HOME_MAP}`) {
      mapData.map_setting.map((mapSetting) => {
        if (mapSetting.type_map !== "MAP") {
          result = [...result,...mapSetting.layer_categories];
          return;
        }
      });
    }
  })

  const resultObj = {
    listGroup : result
  }
  
  console.log('ExtracLayerListGroupFromArrMapData',result);
  return resultObj;
};

export const ExtractListLayerArray = (
  mapData: MapDataModels.MapDataModels
): LayerSettingsModels.LayerSettingsModel[] => {
  const ListLayer: LayerSettingsModels.LayerSettingsModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map !== "MAP") {
      mapSetting.layer_categories.map((layerCategory) => {
        layerCategory.layer_settings.map((layerSetting) =>
          {
            layerSetting.style = "";
            ListLayer.push(layerSetting);
          }
        );
      });
      return;
    }
  });
  return ListLayer;
};

export const ExtractListLayerArrayFromArrMapData = (
  arrMapData: MapDataModels.MapDataModels[]
): LayerSettingsModels.LayerSettingsModel[] => {
  const ListLayer: LayerSettingsModels.LayerSettingsModel[] = [];
  arrMapData?.forEach((mapData) => {
    mapData.map_setting.map((mapSetting) => {
      if (mapSetting.type_map !== "MAP") {
        mapSetting.layer_categories.forEach((layerCategory) => {
          layerCategory.layer_settings.forEach((layerSetting) => {
            if (mapData.planing_id != `${planningIdInfo.planningId_HOME_MAP}`) {
              layerSetting.is_check = false;
            }
            ListLayer.push(layerSetting)
          });
        });
        return;
      }
    });
  })
  
  return ListLayer;
};

export const ExtractExtentArray = (
  mapData: MapDataModels.MapDataModels
): [number, number, number, number] => {
  const extent: [number, number, number, number] = [0, 0, 0, 0];
  mapData.extent
    .split(",")
    .map((numberValue, index) => (extent[index] = Number(numberValue)));
  return extent;
};
export const ExtractCenterArray = (
  mapData: MapDataModels.MapDataModels
): number[] => {
  const center: number[] = [];
  mapData.center
    .split(",")
    .map((numberValue) => center.push(Number(numberValue)));
  return center;
};

export const ExtractBasemapList = (
  mapData: MapDataModels.MapDataModels
): BaseMapModels.BaseMapModel[] => {
  let result: BaseMapModels.BaseMapModel[] = [];
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map === "MAP") {
      result = mapSetting.base_maps;
      return;
    }
  });
  return result;
};

export const ExtractSelectedBasemap = (
  mapData: MapDataModels.MapDataModels
): string => {
  let baseMapUrl = "";
  mapData.map_setting.map((mapSetting) => {
    if (mapSetting.type_map === "MAP") {
      mapSetting.base_maps.map((basemap) => {
        if (basemap.view_default) baseMapUrl = basemap.url;
        return;
      });
      return;
    }
  });
  return baseMapUrl;
};
