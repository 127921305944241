import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import { APIUrlDefault } from "../../../../utils/configuration";
import GaleryCarousel from "./garely-carousel"

function Planning(props) {

    const savePreviewInfo = (previewLabel,imageSrc) => {
        const data = {
            previewLabel:previewLabel,
            imageSrc:imageSrc
        }
        props.savePreviewImage(data);
    }

    const getImageRow = (index,label,rowValue) => {
        return (
            <tr key={index}>
                <td colSpan="2">
                    <div className="image-title">{label}:</div>
                    <div 
                    onClick={() => savePreviewInfo(label,rowValue)} 
                    className="image-show"
                         data-toggle="modal"
                         data-target="#previewImage"
                    ><img src={rowValue} />
                    </div>
                </td>
            </tr>
        )
    }
    const getDataRow = (index,label,rowValue) => {
        return (
            <tr key={index}>
                <td className="row-label" width="50%">{label}</td>
                <td width="50%">{rowValue}</td>
            </tr>
        )
    }
    const getRowData = (data,index) => {
        console.log(data);
        const rowLabel = data.label;
        const rowValue = data.value;
        const arrRow    = rowLabel.split('_');
        let label       = data.label;
        let labelPrefix = '';
        if(Array.isArray(arrRow) && arrRow.length == 2){
            labelPrefix = arrRow[0];
            label       = arrRow[1];
        }
        
        if(label && label == 'avatar'){
            return getImageRow(index,"Hình ảnh",APIUrlDefault + rowValue);
        }if(label && label == 'images'){
            return <GaleryCarousel index={index} label={"Thư viện ảnh"} rowValue={rowValue}/>
        }else{
            return getDataRow(index,label,rowValue);
        }
    }
    return(
        <details id="mapInfomationContainer">
            <summary>
                Thông tin đối tượng
                <img
                    className="position-absolute icon-dropdow right-position"
                    src={require("../../../../assets/icon/arrow-down.png")}
                    alt="arrow-down"
                />
                <img
                    className="position-absolute icon-dropright right-position"
                    src={require("../../../../assets/icon/arrow-right.png")}
                    alt="arrow-right"
                />
            </summary>
            <div className="info-table">
                {props.listMapInfomations?.length >0 ? (
                    <table>
                        <thead>
                        <th  className="row-label" width="50%">Tên trường</th>
                        <th width="50%">Giá trị</th>
                        </thead>
                        <tbody>
                        {props.listMapInfomations?.map((data, index) => {
                                if(data.value){
                                    return getRowData(data,index);
                                }
                            }
                        )}
                        </tbody>
                    </table>
                ):(
                    <p className="pt-3 text-center">Không có dữ liệu</p>
                )}
            </div>
        </details>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            savePreviewImage: MapDataStore.SavePreviewImage,
        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Planning);