import React, { useEffect, useRef } from "react";
import "./map-tools-panel.scss";
import "ol/ol.css";
import Vilanguage from "../../../languages/vi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as MapToolsPanelsStore from "../../../redux/store/open-layer/map-tools-panels.store";
import { Tooltip } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const LanguageCollect = Vilanguage;

const ButtonTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "black",
    color: "white",
    fontSize: 11,
    opacity: 0.5,
  },
}))(Tooltip);

function MapToolsPanel(props) {
  const isFullScreenMode = useRef(false);
  const isTurnOnPinMarkerMode = useRef(false);
  const isTurnOnViewInfomationOnClick = useRef(true);
  const isTurnOnMeasureMode = useRef(false);
  const isTurnOnFilterMode = useRef(false);

  const turnOnMeasureLineMode = () => {
    isTurnOnMeasureMode.current = false;
    autoTurnOffAllFeature();
    removeInteractionOnMap();
    props.openLayerController.handleMeasureMode(true, true);
    isTurnOnMeasureMode.current = true;
  };

  const turnOnMeasurePolygonMode = () => {
    isTurnOnMeasureMode.current = false;
    autoTurnOffAllFeature();
    removeInteractionOnMap();
    props.openLayerController.handleMeasureMode(true, false);
    isTurnOnMeasureMode.current = true;
  };

  const togglePinMarkerMode = (turnOn) => {
    if (turnOn) autoTurnOffAllFeature();
    props.openLayerController.handlePinMarkerMode(turnOn);
    isTurnOnPinMarkerMode.current = turnOn;
  };

  const toggleDisplayFullscreenMode = () => {
    props.openLayerController.handleDisplayFullScreenViewMode(
      !isFullScreenMode.current
    );
    isFullScreenMode.current = !isFullScreenMode.current;
  };

  const autoTurnOffAllFeature = () => {
    if (isTurnOnViewInfomationOnClick.current && !isTurnOnPinMarkerMode.current)
      toggleViewInfomationOnClick(false);
    if (isTurnOnPinMarkerMode.current) togglePinMarkerMode(false);
    if (isTurnOnMeasureMode.current)
      props.openLayerController.handleMeasureMode(false, false);
    if (isTurnOnFilterMode.current) handleControlFilterMode(false);
  };

  const zoomIn = () => {
    props.openLayerController.handleZoomIn();
  };

  const zoomOut = () => {
    props.openLayerController.handleZoomOut();
  };

  const exportMap = (isSave) => {
    props.openLayerController.handleExportMap(isSave);
  };

  const moveViewReport = () => {
    autoTurnOffAllFeature();
    removeInteractionOnMap();
  };

  const selectOne = () => {
    autoTurnOffAllFeature();
    removeInteractionOnMap();
    props.openLayerController.handleSelectedOne();
  };

  const selectMany = () => {
    autoTurnOffAllFeature();
    removeInteractionOnMap();
    props.openLayerController.handleSelectMany();
  };

  const toggleViewInfomationOnClick = (turnOn) => {
    // removeInteractionOnMap();
    if (turnOn) autoTurnOffAllFeature();
    props.openLayerController.handleOnOffViewInfomation(turnOn);
    // isTurnOnViewInfomationOnClick.current = turnOn;
  };

  const viewCurrentLocation = () => {
    autoTurnOffAllFeature();
    props.openLayerController.handleViewCurrentLocaltion();
  };

  const removeInteractionOnMap = () => {
    props.openLayerController.removeInteractionOnMap();
  }

  function handleControlFilterMode(isTurnOn = false) {
    if (isTurnOn) {
      autoTurnOffAllFeature();
      props.SetDisplayInfomationPopup(true);
    } else {
      props.openLayerController.functionTurnOffFilterMode();
    }
    isTurnOnFilterMode.current = isTurnOn;
  }

  const listTools = [
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.viewObjectInfomation,
      action: () => toggleViewInfomationOnClick(true),
      icon: "info",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.toggleFullScreen,
      action: toggleDisplayFullscreenMode,
      icon: "full-screen",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.zoomIn,
      action: zoomIn,
      icon: "zoomIn",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.zoomOut,
      action: zoomOut,
      icon: "zoomOut",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.filterInfomation,
      action: () => handleControlFilterMode(true),
      icon: "filter",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.selectAnObject,
      action: selectOne,
      icon: "selectOne",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.selectMultiObject,
      action: selectMany,
      icon: "selectArea",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.measuareArea,
      action: turnOnMeasurePolygonMode,
      icon: "measureArea",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.measuareDistance,
      action: turnOnMeasureLineMode,
      icon: "ruler",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.moveViewport,
      action: moveViewReport,
      icon: "finger",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.printTheMap,
      action: () => exportMap(false),
      icon: "print",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.myPosition,
      action: viewCurrentLocation,
      icon: "position",
    },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.exportMap,
      action: () => exportMap(true),
      icon: "photo",
    },
    // {
    //     title: LanguageCollect.planningMapView.mapToolsPanel.compare,
    //     action: mockFunction,
    //     icon: "slideshow"
    // },
    {
      title: LanguageCollect.planningMapView.mapToolsPanel.locationMarker,
      action: () => togglePinMarkerMode(true),
      icon: "marker",
    },
  ];

  function handleChangeClassStateButton(indexActive) {
    const ListButton = Array.from(
      document.getElementsByClassName("tool-button")
    );
    ListButton.map((Button, index) => {
      if (indexActive !== index) Button.classList.remove("active");
      else Button.classList.add("active");
    });
  }

  useEffect(() => {
    const ListButton = Array.from(
      document.getElementsByClassName("tool-button")
    );
    ListButton.map((Button, index) => {
      Button.addEventListener("click", () =>
        handleChangeClassStateButton(index)
      );
    });
    if (ListButton[0]) ListButton[0].classList.add("active");
  }, [props.isDisplayPanel]);

  return props.isDisplayPanel ? (
    <div
      id="map-tool-list"
      className="d-flex position-absolute map-tool-panel-container"
      hidden={!props.isDisplayPanel}
    >
      {listTools.map((Tool, index) => (
        <RenderButtonTool key={index} data={Tool} />
      ))}
    </div>
  ) : null;
}

function RenderButtonTool(props) {
  const data = props.data;
  let styleCustom = {};
  switch (data.title) {
    case LanguageCollect.planningMapView.mapToolsPanel.toggleFullScreen:
    case LanguageCollect.planningMapView.mapToolsPanel.zoomIn:
    case LanguageCollect.planningMapView.mapToolsPanel.zoomOut:
      styleCustom = { pointerEvents: "inherit" };
  }

  return (
    <div>
      <ButtonTooltip title={data.title} placement="left" arrow>
        <button
          className="tool-button"
          data-icon-name={data.icon}
          onClick={() => data.action()}
          style={styleCustom}
        >
          <img
            src={require(`../../../assets/icon/${data.icon}.svg`)}
            alt={data.title}
          />
        </button>
      </ButtonTooltip>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isDisplayPanel: state.mapToolsPanel.displayPanel,
  openLayerController: state.openlayer.openLayerController,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      SetDisplayInfomationPopup: MapToolsPanelsStore.SetDisplayInfomationPopup,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(MapToolsPanel);
