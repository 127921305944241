import React, { Fragment, useState, useContext } from "react";

const TitleWhenClickTopRightPanelContext = React.createContext();
const UpdateTitleWhenClickTopRightPanelContext = React.createContext();
const ContentWhenClickTopRightPanelContext = React.createContext();
const UpdateContentWhenClickTopRightPanelContext = React.createContext();

export function TopRightPanelProvider({children}) {
    const [components, setComponents] = useState([]);
    const [title, setTitle] = useState();
    const appendChild = (component) => {
        setComponents([...components,component])
    }
    return (
        <ContentWhenClickTopRightPanelContext.Provider value={components}>
            <UpdateContentWhenClickTopRightPanelContext.Provider value={appendChild}>
                <TitleWhenClickTopRightPanelContext.Provider value={title}>
                    <UpdateTitleWhenClickTopRightPanelContext.Provider value={setTitle}>
                        {children}
                    </UpdateTitleWhenClickTopRightPanelContext.Provider>
                </TitleWhenClickTopRightPanelContext.Provider>
            </UpdateContentWhenClickTopRightPanelContext.Provider>
        </ContentWhenClickTopRightPanelContext.Provider>
    )
}

export function useContentWhenClickTopRightPanel() {
    return useContext(ContentWhenClickTopRightPanelContext);
}

export function useUpdateContentInBottomToggle() {
    return useContext(UpdateContentWhenClickTopRightPanelContext);
}

export function useTitleWhenClickTopRightPanel() {
    return useContext(TitleWhenClickTopRightPanelContext);
}

export function useUpdateTitleWhenClickTopRightPanel() {
    return useContext(UpdateTitleWhenClickTopRightPanelContext);
}