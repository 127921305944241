import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";

const service = new Service();

export const MapFilter = (data) => {
    return service.post(ApiUrl.MapFilter, data).then((res) => {return res}).catch((err) => { throw err });
}
export const GetDropDown = (data) => { 
    return service.post(ApiUrl.GetDropDown, data).then((res) => {return res}).catch((err) => { throw err });
}
export const SpatialSearch = (data) => { 
    return service.post(ApiUrl.SpatialSearch, data).then((res) => {return res}).catch((err) => { throw err });
}

export const SET_DATA_TOGGLE_LAYER = "MAPTOOL/SET_DATA_TOGGLE_LAYER";
export const UPDATE_DATA_TOGGLE_LAYER = "MAPTOOL/UPDATE_DATA_TOGGLE_LAYER";

const setDataToggleLayer = (data) => ({
    type: SET_DATA_TOGGLE_LAYER,
    data: data,
});

const updateDataToggleLayer = (data) => ({
    type: UPDATE_DATA_TOGGLE_LAYER,
    data: data,
});

export const SetDataToggleLayer = (data) => {
    return (dispatch) => {
        dispatch(setDataToggleLayer(data));
    };
};


export const UpdateDataToggleLayer = (data) => {
    console.log(UpdateDataToggleLayer);
    return (dispatch) => {
        dispatch(updateDataToggleLayer(data));
    };
};

const InitState = {
    dataToggleLayer: [],
    test: {}
};

export default function MapToolDataReducer(state = InitState, action) {
    switch (action.type) {
        case SET_DATA_TOGGLE_LAYER:
            return { ...state, dataToggleLayer: action.data };
        case UPDATE_DATA_TOGGLE_LAYER: {
            const itemChange = action.data;
            const newDataToggleLayer = [...state.dataToggleLayer];
            console.log('MapToolDataReducer',newDataToggleLayer);
            newDataToggleLayer.forEach(groupLayer => {
                groupLayer.layer_settings.forEach(item => {
                    if(item.id == itemChange.id) {
                        item.is_check = itemChange.is_check;
                        return;
                    }
                    
                    if(item.layerRealationships?.length > 0) {
                        item.layerRealationships.forEach(layerRela => {
                            if(layerRela.id == itemChange.id) {
                                layerRela.is_check = itemChange.is_check;
                                return;
                            }
                        })
                    }
                })
            });
            return { ...state, dataToggleLayer: newDataToggleLayer };
        }
        default:
            return state;
    }
}