import React, { useEffect, useState } from "react";
import TopView from "../top/top.view.jsx";
import HeaderView from "../header/header.view.jsx";
import FooterView from "../footer/footer.view.jsx";
import LoadingWithApiQueue from "../loading/loading-with-queue.view";
import "./layout.scss";


function LayoutDesktopView(props) {

  const [bodyHeight, setBodyHeight] = useState({minHeight: `${window.innerHeight}px`});

  useEffect(() => {
    const FooterContainerHeight = document.getElementsByClassName(
      "footer-container"
    )[0].offsetHeight;
    const HeaderContainerHeight = document.getElementsByClassName(
      "header-container"
    )[0].offsetHeight;

    setBodyHeight({
      minHeight: `${
        window.innerHeight -
        FooterContainerHeight -
        HeaderContainerHeight -
        10
      }px`,
    })
  }, []); 

  return (
    <div className="layout-container">
      <TopView />
      <HeaderView match={props.match} />
      <div style={bodyHeight}>
        {props.children}
      </div>
      <FooterView />
      <LoadingWithApiQueue />
    </div>
  )
}

export default LayoutDesktopView;

