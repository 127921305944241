/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, lazy, Suspense  } from "react";
import { useMediaQuery } from 'react-responsive'
import PlanningMapViewWithHooks from './planning-map-with-hooks.view';

function PlanningMapRoute(props) {
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1024px)'});
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)'});
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  return (
    <PlanningMapViewWithHooks isTabletOrMobile={isTabletOrMobile} isDesktopOrLaptop={isDesktopOrLaptop} {...props}/>
  )
}

export default PlanningMapRoute;
