import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import OpenLayerHomeView from "./openLayer-home.view";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import * as homePageStore from "../../../redux/store/home-page/home-page.store";
import {
    ExtractSelectedBasemap,
    ExtractCenterArray,
    ExtractExtentArray,
    ExtractListLayerArray,
    ExtracLayerListGroup,
} from "../../../common/extract-information-from-map-data";
import { planningIdInfo } from "../../../mock-data/planningIdMock.mock";
function HomeMap(props) {
    const planningId = planningIdInfo.planningId_HOME_MAP;

    useEffect(() => {
        props.GetHomeMapDetail(planningId);
    }, []);
    
    console.log('props.homeMapData',props.homeMapData);

    return (
        <>
            {props.homeMapData.name && (
                <OpenLayerHomeView
                    defaultBaseMapUrl={ExtractSelectedBasemap(props.homeMapData)}
                    defaultCenter={ExtractCenterArray(props.homeMapData)}
                    defaultZoom={props.homeMapData.zoom}
                    projection={props.homeMapData.projection}
                    maxZoom={props.homeMapData.max_zoom}
                    minZoom={props.homeMapData.min_zoom}
                    extent={ExtractExtentArray(props.homeMapData)}
                    listLayer={ExtractListLayerArray(props.homeMapData)}
                    // listGroupLayer={ExtracLayerListGroup(props.homeMapData)}
                />
            )}
            
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    hasLoadedMapData: state.mapData.hasLoadingData,
    homeMapData: state.mapData.homeMapData,
    planningRelationShips: state.mapData.planningRelationShips,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
            GetHomeMapDetail: MapDataStore.GetHomeMapDetailByPlanningId,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(HomeMap);