const IS_EMBEDDED = "IS_EMBEDDED";

const IsEmbedded = (data) => ({ type: IS_EMBEDDED, data: data })

export const SetIsEmbedded = (data) => {
    return dispatch => dispatch(IsEmbedded(data))
}

const InitState = {
    isEmbedded: false,
}

export default function EmbeddedReducer(state = InitState, action) {
    switch (action.type) {
        case IS_EMBEDDED: {
            console.log("Màn mobile gọi", action);
            return { ...state, isEmbedded: true }
        }
        default: return state
    }
}