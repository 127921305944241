import React, { useEffect, useState, useContext } from "react";

const IsOpenBottomPanelContext = React.createContext();
const ToggleBottomPanelContext = React.createContext();

const IsExtendBottomPanelContext = React.createContext();

const OpenBottomPanelContex = React.createContext();
const ExtendBottomPanelContex = React.createContext();

export function ToggleBottomProvider({children}) {
    const [isBottomOpen, setBottomOpen] = useState(false);
    const [isExtend, setExtend] = useState(false);

    function toggleBottom() {
        setBottomOpen(prev => !prev);
    }

    const openBottomPanel = () => {
        console.log("Swipeeee bottom",isBottomOpen, isExtend);
        if(isBottomOpen && isExtend) {
            setExtend(false);
        } else if (isBottomOpen && !isExtend) {
            toggleBottom();
        }
    }

    const extendBottomPanel = () => {
        console.log("Swipeeee top");
        if(isExtend) return;
        setExtend(prev => !prev);
    }    

    useEffect(()=> {        
        if(!isBottomOpen) setExtend(false);
        else {
            if(isExtend) {
                const bottomPanelContent = document.getElementById("bottom-panel_content");
                if(bottomPanelContent) bottomPanelContent.style.height = '70vh'
            } else {
                const bottomPanelContent = document.getElementById("bottom-panel_content");
                if(bottomPanelContent) bottomPanelContent.style.height = '40vh'
            }
        }

    }, [isBottomOpen,isExtend])
    
    return (
        <IsOpenBottomPanelContext.Provider value={isBottomOpen}>
            <ToggleBottomPanelContext.Provider value={toggleBottom}>
                <IsExtendBottomPanelContext.Provider value={isExtend}>
                    <OpenBottomPanelContex.Provider value={openBottomPanel}>
                        <ExtendBottomPanelContex.Provider value={extendBottomPanel}>
                            {children}
                        </ExtendBottomPanelContex.Provider>
                    </OpenBottomPanelContex.Provider>
                </IsExtendBottomPanelContext.Provider>
            </ToggleBottomPanelContext.Provider>
        </IsOpenBottomPanelContext.Provider>
    )
}

export function useIsOpenBottomPanel() {
    return useContext(IsOpenBottomPanelContext);
}

export function useToggleBottomPanel() {
    return useContext(ToggleBottomPanelContext);
}

export function useIsExtendBottomPanel() {
    return useContext(IsExtendBottomPanelContext);
}

export function useOpenBottomPanel() {
    return useContext(OpenBottomPanelContex);
}

export function useExtendBottomPanel() {
    return useContext(ExtendBottomPanelContex);
}