import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import * as MapDataStore from "../../../../redux/store/map-data/map-data.store";
import { APIUrlDefault } from "../../../../utils/configuration";
import Slider from "react-slick";
import "./garely-carousel.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";

const settings = {
    // dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

function GaleryCarousel(props) {
    const {index,label,rowValue} = props;

    const ref = useRef({}); 

    const next = () => {
        ref.current.slickNext();
    };

    const previous = () => {
        ref.current.slickPrev();
    };

    const listImgGalery = rowValue?.split(",");

    useEffect(() => {
        if ( label && rowValue ) {
            const data = {
                previewLabel: label,
                listImgGalery: rowValue?.split(",")
            }
            props.savePreviewGalery(data);
        }

        return () => {
            console.log("Galery unmounted");
            props.savePreviewGalery(null);
            props.saveGaleryPreviewIndex(null)
        }
    }, [label, rowValue])

    return(
        <tr key={index}>
            <td colSpan="2">
                <div className="image-title">{label}:</div>
                <div style={{position: 'relative'}}>
                    <Slider ref={ref} {...settings} className="image-show">
                        {
                            listImgGalery.map((item, index) => (
                                <div className="img_frame_for_slick" key={index} onClick={() => props.saveGaleryPreviewIndex(index)} data-toggle="modal" data-target="#previewImage">
                                    <img src={APIUrlDefault + item} />
                                </div>
                            ))
                        }
                    </Slider>
                    <button type="button" className="scroll_button left" title="Button left" 
                        onClick={() => {previous()}}>
                        <FontAwesomeIcon
                            icon={faAngleLeft}
                            color="#70757a"
                            size="lg"
                        />
                    </button>
                    <button type="button" className="scroll_button right" title="Button right"
                        onClick={() => {next()}}>
                        <FontAwesomeIcon
                            colorInterpolation={"black"}
                            icon={faAngleRight}
                            color="#70757a"
                            size="lg"
                        />
                    </button>
                </div>
            </td>
        </tr>
    );

}

const mapStateToProps = (state) => ({

});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            savePreviewImage: MapDataStore.SavePreviewImage,
            savePreviewGalery: MapDataStore.SavePreviewGalery,
            saveGaleryPreviewIndex: MapDataStore.SaveGaleryPreviewIndex
        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(GaleryCarousel);