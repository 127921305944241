/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, lazy, Suspense  } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as statementActions from "../../redux/store/statement/statement.store";
import * as embeddedActions from "../../redux/store/embedded/embedded.store";
import LoadingWithApiQueue from '../loading/loading-with-queue.view';

function LayoutMobileEmbeddedView(props) {
  const location = useLocation();

  useEffect(() => {
    props.setEmbeddedStatus(true);
    props.getAllPlanningType();
    props.getAllPlanningKind();
    props.getAllPlaceType();
  }, [])

  return (
    <div className="layout-map-page-container">
        <div>
            {props.children}
        </div>
        <LoadingWithApiQueue />
    </div>
  )
}


const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
    {
        getAllPlanningType: statementActions.PlanningTypeDispatch,
        getAllPlanningKind: statementActions.PlanningKindDispatch,
        getAllPlaceType: statementActions.PlaceTypeDispatch,
        setEmbeddedStatus: embeddedActions.SetIsEmbedded,
    },
    dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LayoutMobileEmbeddedView);
  
  