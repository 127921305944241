import React from 'react';
import './guide-use-map.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
export default function GuideUseMap(props: any) {
    return (
        <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            aria-labelledby="myLargeModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div
                    className="modal-content modal-content-custom"
                    style={{ height: `${props.modalHeightStyle}px` }}
                >
                    <div className="modal-content-header text-center position-relative">
                        Hướng dẫn sử dụng
                        <FontAwesomeIcon
                            type="button"
                            data-dismiss="modal"
                            aria-label="Close"
                            icon={faTimes}
                            color="white"
                            size="lg"
                        />
                    </div>
                    <div className="guide-content">
                        <img src={require("../../../assets/image/guide/1.jpg")} />
                        <img src={require("../../../assets/image/guide/2.jpg")} />
                        <img src={require("../../../assets/image/guide/3.jpg")} />
                        <img src={require("../../../assets/image/guide/4.jpg")} />
                    </div>
                </div>
            </div>
        </div>
    )
}