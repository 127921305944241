export const planningIdInfo = {
    planningId_POINT_MAP : 29,
    planningId_HOME_MAP : 30,
    planningId_KHQHSDD : 25, // chốt
    planningId_GT : 27, // chốt
    planningId_DADTXD : 28, // ko dùng
    
    HOME_MAP_LAYER_ID : 64,
    HOME_MAP_GEO_LAYER_NAME : 'sudungdat_wgs84',
    PROJECT_MAP_LAYER_ID: 84, // ko dùng
} 