const UrlCollect = {
  Home: "/",
  SpecializedPlanning: "/quy-hoach-chuyen-nganh",
  SyntheticPlanning: "/tong-hop-quy-hoach",
  GeneralPlanning: "/quy-hoach-chung",
  SubdivisionPlanning: "/quy-hoach-phan-khu",
  DetailedPlanning: "/quy-hoach-chi-tiet",
  PlanningAnnouncement: "/cong-bo-quy-hoach",
  News: "/news",
  RecordsManager: "/quan-ly-ho-so",
  Contact: "/lien-he",
  PlanningMapViewDetail: "/ban-do-quy-hoach/",
  PlanningMap: "/ban-do-quy-hoach",
  ktqhsdd: "/ke-hoach-quy-hoach-sdd/",
  giao_thong: "/quy-hoach-giao-thong/",
  //-- User
  Login: "/dang-nhap",
  Register: "/dang-ky",
  ForgotPassword: "/quen-mat-khau",
  ConfirmCode: "/xac-nhan",
  ResetPassword: "/dat-lai-mat-khau",

  //-- Xin y kien cong dong
  PleaseConsultTheCommunity: "/y-kien-cong-dong",
  PleaseComment: "/xin-y-kien",
  SummaryIdeas: "/y-kien-tong-hop",

  //-- Tin tuc
  NewsList: "/tin-tuc",
  NewsDetail: "/tin-tuc-chi-tiet",

  //-- Error
  Page500: '/page-500/',
  // ProjectManagement: '/du-an-dau-tu-xay-dung',
  //-- Admin
  ProcessBoardPlanning:"/quy-trinh-cong-bo-quy-hoach",

  //-- Embedded Mobile
  SyntheticPlanningMobile: "/tong-hop-quy-hoach-mb",
  PlanningAnnouncementMobile: "/cong-bo-quy-hoach-mb",
  ktqhsdd_Mobile: "/ke-hoach-quy-hoach-sdd-mb/", // + "id"
  giao_thong_Mobile: "/quy-hoach-giao-thong-mb/", // + "id"
  // ProjectManagementMobile: '/du-an-dau-tu-xay-dung-mb',
  PlanningMapViewDetailMobile: "/ban-do-quy-hoach-mb/",
  PlanningMapMobile: "/ban-do-quy-hoach-mb",
};

export default UrlCollect