const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://caobang-api.cgis.asia/",
        domainUserSide: "http://localhost:3000",
        domainAdminSide: "http://localhost:3001",
        domainName: "",
        doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
        doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/caobang/wms",
        datastore: "caobang",
  },
  production: {
      api: "https://caobang-api.cgis.asia/",
      domainUserSide: "https://caobang.cgis.asia",
      domainAdminSide: "https://admin.caobang.cgis.asia",
      domainName: "caobang.cgis.asia",
      doMainNameGeoServer: "https://geo.cgis.asia/geoserver/wfs",
      doMainNameGeoServerWMS: "https://geo.cgis.asia/geoserver/caobang/wms",
      datastore: "caobang",
  },
};

module.exports = apiEnvironment[env];
