/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, lazy, Suspense  } from "react";
import { useMediaQuery } from 'react-responsive'
import ProjectMapViewWithHooks from "./project-map-with-hools.view"

import { TopRightPanelProvider } from "../../components/mobile-bottom-toggle/mobile-bottom-toggle-content/TopRightPanelContext";
import { ToggleBottomProvider } from "../../components/mobile-bottom-toggle/context/ToggleBottomPanelContext";
import { AddContentBottomPanelProvider } from "./mobile/context/content-bottom-panel/ContentBottomPanel";
import { IsHideFilterBoxProvider } from "./mobile/context/is-hide-filter-box/IsHideFilterBox";
import { DataProjectProvider, useSetDataProject } from "./mobile/context/data-project/DataProject";

function ProjectMapRoute(props) {
  return (
    <ToggleBottomProvider>
      <TopRightPanelProvider>
        <AddContentBottomPanelProvider>
          <IsHideFilterBoxProvider>
            <DataProjectProvider>
              <ProjectMapRouteContentWrapper />
            </DataProjectProvider>
          </IsHideFilterBoxProvider>
        </AddContentBottomPanelProvider>
      </TopRightPanelProvider>
    </ToggleBottomProvider>
  )
}

function ProjectMapRouteContentWrapper (props) {
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1024px)'});
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' });
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)'});
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' });

  const planningId = 13;
  const setDataProject = useSetDataProject();

  return (
    <ProjectMapViewWithHooks
      isTabletOrMobile={isTabletOrMobile}
      isDesktopOrLaptop={isDesktopOrLaptop}
      isProject={true}
      planningId={planningId}
      setDataProject={setDataProject}
    />
  )
}
export default ProjectMapRoute;
