/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { geolocated } from "react-geolocated";
import history from "../../../common/history";
import UrlCollect from "../../../common/url-collect";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { AsyncPaginate } from "react-select-async-paginate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCogs,
  faMap as baseMapIcon,
  faInfoCircle,
  faSlidersH,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";

import * as StatementAction from "../../../redux/store/statement/statement.store";
import {
  SetDisplayMaptoolPanel,
  SetDisplayInfomationPopup,
} from "../../../redux/store/open-layer/map-tools-panels.store";
import HandleErrorOnGetGeoloaction from '../../../common/handle-error-navigator-geolocation';
import {
  CoordsModel,
  PlanningModelMapTool,
} from "../../../models/planning-map-view/top-right-panel-models";
import ViLanguages from "../../../languages/vi";
import { Tooltip, Chip } from '@material-ui/core';
import { ArrowLeft , ArrowDropDown, Restaurant, SchoolRounded, LocalHospital, LocalDining, CardTravelSharp, LocalPharmacy, LocalHospitalTwoTone } from '@material-ui/icons';
import "./assistive-chips.scss";
import * as MapDataStore from "../../../redux/store/map-data/map-data.store";
import { GetLatLngPlace } from "../../../redux/store/home-page/home-page.store";
import {
  APIUrlDefault,
  NotificationMessageType,
  NotificationPosition,
} from "../../../utils/configuration";
import * as mapHelper from "../../../common/map";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import * as viVN from "../../../languages/vi-VN.json";
import * as map from "../../../common/map";
import LayerCategoryModels from "../../../models/map-data-model-b/layer-category-models";
import LayerSettingsModels from "../../../models/map-data-model-b/layer-setting-models";
import {isMobile} from 'react-device-detect';
import OpenlayerController from "../../../models/open-layer/openlayer-controller-custom.model";
import BaseMapModels from "../../../models/map-data-model-b/base-map-models";
import {
  faAngleLeft,
  faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import iconXayDung from "../../../assets/icon/Xay-dung-20.svg"
import iconQuyHoach from "../../../assets/icon/Quy-hoach-20.svg"
import iconGiaoThong from "../../../assets/icon/Giao-thong-20.svg"
import iconDuAn from "../../../assets/icon/Du-an-20.svg"
import { planningIdInfo } from "../../../mock-data/planningIdMock.mock";
import { useClickOutside } from '../../../hooks/useClickOutside';
import ClickOutside from "../../../components/click-outside/click-outside";
// @ts-ignore
import SwipeListener from 'swipe-listener';

const LanguageCollects = ViLanguages;

interface AssistiveChipsPropsModel {
  // coords?: CoordsModel;
  // isGeolocationEnabled?: boolean;
  openLayerController: OpenlayerController;
  mapData: any;
  isHiddenMapTools?: boolean;
  SetDisplayInfomationPopup: Function;
  functionClickViewInfomationListener?: Function;
  SetDisplayMaptoolPanel: Function;
  isMapToolsPanelsDisplay: boolean;
  isHiddenSearch?: boolean;
  isHiddenLayer?: boolean;
  isHiddenInfor?: boolean;
  planningId?: number;
  setPlanningModelInMap?: Function;
  listBoundaries: any[];
  listPolygon: any;
  GetAllPlanningBoundariesGeomText?: Function;
  layerGroupsPlace: {
    'listGroup' : LayerCategoryModels.LayerCategoryModel[];
  };
  layerGroups_KHQHSDD: {
    'listGroup' : LayerCategoryModels.LayerCategoryModel[];
  };
  layerGroups_GT: {
    'listGroup' : LayerCategoryModels.LayerCategoryModel[];
  };
  layerGroups_DADTXD: {
    'listGroup' : LayerCategoryModels.LayerCategoryModel[];
  };
  planningType: any;
  planningKind: any;
  placeType: any;
  fixedDataGroupLayers? :any;
  setFixedDataGroupLayers? : Function;
  idPlanningToHide? : number;
  isProject?: boolean;
}

function AssistiveChipsView(props: AssistiveChipsPropsModel) {
  
  const [layerGroupsPlace, setListGroupLayerPlace] = useState<any>(null);

  useEffect(() => {
    function handleSwipe (e: any) {
      var x = e.detail.x;
      
      var y = e.detail.y;
     
      if (x[1] - x[0] < 0) {
        console.log('Swiped left.');
        onClickScrollLeftButton(x[1] - x[0])
      } else {
        console.log('Swiped right.');
        onClickScrollRightButton(x[1] - x[0])
      }
     
      console.log('Started horizontally at', x[0], 'and ended at', x[1]);
    }
    var containerChipsWrap =  document.querySelector('#chips_wrap');
    var swipeListener = SwipeListener(containerChipsWrap);

    containerChipsWrap?.addEventListener('swiping', handleSwipe);

    return () => {
      containerChipsWrap?.removeEventListener('swiping', handleSwipe);
    };
  }, [])

  useEffect(()=>{
    const tempLayerPoints = {...props.layerGroupsPlace};
    tempLayerPoints.listGroup?.forEach((group: LayerCategoryModels.LayerCategoryModel)=>{
      group.haveItemChecked = false;
    })

    setListGroupLayerPlace(tempLayerPoints);
  },[props.layerGroupsPlace])

  const [layerGroups_KHQHSDD, setListGroupLayer_KHQHSDD] = useState<any>(null);

  useEffect(()=>{
    const tempLayerPoints = {...props.layerGroups_KHQHSDD};
    tempLayerPoints.listGroup?.forEach((group: LayerCategoryModels.LayerCategoryModel)=>{
      group.haveItemChecked = false;
    })

    setListGroupLayer_KHQHSDD(tempLayerPoints);
  },[props.layerGroups_KHQHSDD])

  const [layerGroups_GT, setListGroupLayer_GT] = useState<any>(null);

  useEffect(()=>{
    const tempLayerPoints = {...props.layerGroups_GT};
    tempLayerPoints.listGroup?.forEach((group: LayerCategoryModels.LayerCategoryModel)=>{
      group.haveItemChecked = false;
    })

    setListGroupLayer_GT(tempLayerPoints);
  },[props.layerGroups_GT])

  const [layerGroups_DADTXD, setListGroupLayer_DADTXD] = useState<any>(null);

  useEffect(()=>{
    const tempLayerPoints = {...props.layerGroups_DADTXD};
    tempLayerPoints.listGroup?.forEach((group: LayerCategoryModels.LayerCategoryModel)=>{
      group.haveItemChecked = false;
    })

    setListGroupLayer_DADTXD(tempLayerPoints);
  },[props.layerGroups_DADTXD])

  function handleToggleDisplayLayer(
    layerTarget: LayerSettingsModels.LayerSettingsModel
  ) {
    props.openLayerController?.toggleDisplayLayer(layerTarget);
  }

  const [triggerRerender, setTriggerRerender] = useState(false);
  const [planningKind, setPlanningKind] = useState([]);

  useEffect(()=>{
    console.log('CHECK props planningKind', props.planningKind);
    setPlanningKind(props.planningKind);
  },[props.planningKind])

  function handleClickCheckbox(LayerIndex: number, newValue: boolean, id: number) {
    let havingIsCheckPoints = false;
    layerGroupsPlace.listGroup?.map((group: LayerCategoryModels.LayerCategoryModel)=>{
      if(group.id == id){
        console.log('handleClickCheckbox',group.layer_settings[LayerIndex],id);
        group.layer_settings[LayerIndex].is_check = newValue;
        setTriggerRerender((prev)=>{return !prev})
        props.openLayerController?.toggleDisplayLayer(group.layer_settings[LayerIndex]);
        
        group.layer_settings.forEach((layer) => {
          if (layer.is_check) {
            havingIsCheckPoints = true;
            return;
          }
        })

        group.haveItemChecked = havingIsCheckPoints;
      }
    })
  
    // if(isMobile){
    //   props.toggleLeftNavBarAction();
    // }
  }

  const onClickScrollLeftButton = (scrollValueLeft?: number) => {
    const chipWrap = document.getElementsByClassName("chip_wrap") as HTMLCollection;
    const chipWrapArray = Array.from(chipWrap) as HTMLElement[];

    let totalChipWidth = 0;
    chipWrapArray.forEach(item => {
      const style = getComputedStyle(item)
      const marginLeft = parseFloat(style.marginLeft);
      const marginRight = parseFloat(style.marginRight);
      const width  = parseFloat(style.width);
      totalChipWidth += (marginLeft + marginRight + width);
    });

    const chips_wrap = document.getElementsByClassName("chips_wrap")[0] as HTMLElement;
    const contentWrapWidth  = parseFloat(getComputedStyle(chips_wrap).width);

    
    const minLeft = contentWrapWidth - totalChipWidth;

    let currentLeft = parseInt(getComputedStyle(chips_wrap).getPropertyValue('left'), 10);

    let newLeft = 0;

    if(scrollValueLeft) {
      newLeft = currentLeft + scrollValueLeft;
    } else {
      newLeft = currentLeft - 100;
    }
     

    if (newLeft >= minLeft) {
      chips_wrap.style.left = newLeft + "px";
      console.log("onClickScrollLeftButton RUN");
    } else {
      chips_wrap.style.left = minLeft + "px";
      console.log("onClickScrollLeftButton NOT RUN");
    }

    console.log("onClickScrollLeftButton currentLeft",currentLeft);
    console.log("onClickScrollLeftButton newLeft", newLeft);
    console.log("onClickScrollLeftButton minLeft", minLeft);
  }

  const onClickScrollRightButton = (scrollValueRight?: number) => {
    const chips_wrap = document.getElementsByClassName("chips_wrap")[0] as HTMLElement;

    let currentLeft = parseInt(getComputedStyle(chips_wrap).getPropertyValue('left'), 10);

    let newLeft = 0;

    if(scrollValueRight) {
      newLeft = currentLeft + scrollValueRight;
    } else {
      newLeft = currentLeft + 100;
    }

    if (newLeft <= 0) {
      chips_wrap.style.left = newLeft + "px";
      console.log("onClickScrollLeftButton RUN");
    } else {
      chips_wrap.style.left = "0px";
      console.log("onClickScrollLeftButton NOT RUN");
    }

  }

  const onClickChipForPlace = (group: any) => {
    if(group.layer_settings.length > 0){
      console.log("Có list dropdown",group.folder_label,group.id);
      openDropdownForPlace(group);
    } else {
      console.log("Không có list dropdown");
    }
  }
  
  const getDataGroupLayers: any = (typeId: number) => {
    const resultData: any[] = [];
    props.fixedDataGroupLayers?.['giaothong'].forEach((item: any) => {
      if (item.typeId == typeId) {
        resultData.push({...item, 'kindLayer' : 'giaothong'})
      }
    });
    props.fixedDataGroupLayers?.['sdd'].forEach((item: any) => {
      if (item.typeId == typeId) {
        resultData.push({...item, 'kindLayer' : 'sdd'})
      }
    });
    return resultData;
  }

  const getHaveItemChecked: any = (typeId: number, kindId: number) => {
    let result = false;
    props.fixedDataGroupLayers?.['giaothong'].forEach((item: any) => {
      if (item.typeId == typeId) {
        // console.log('giaothong',item,typeId);
        if(item.isChecked) {
          result = true;
          return;
        }
      }
    });
    props.fixedDataGroupLayers?.['sdd'].forEach((item: any) => {
      if (item.typeId == typeId) {
        // console.log('sdd',item,typeId);
        if(item.isChecked) {
          result = true;
          return;
        }
      }
    });
    return result;
  }

  const getChipHaveItemChecked: any = (kindId: number) => {
    let result = false;
    props.fixedDataGroupLayers?.['giaothong'].forEach((item: any) => {
        if(item.isChecked) {
          result = true;
          return;
        }
    });
    props.fixedDataGroupLayers?.['sdd'].forEach((item: any) => {
        if(item.isChecked) {
          result = true;
          return;
        }
    });
    
    // props.planningKind.forEach((kind: any) => {
    //   if (kind.id == kindId) {
    //     kind.haveItemChecked = result;
    //   }
    // });

    return result;
  }

  const setFixedDataGroupLayers = (item: any) => {
    const copyFixedDataGroupLayers = {...props.fixedDataGroupLayers};
    copyFixedDataGroupLayers[`${item.kindLayer}`].map((layerData: any) => {
      if(layerData.typeId == item.typeId) {
        layerData.isAddedLayer = item.isAddedLayer;
        layerData.isChecked = item.isChecked;
      }
    })
    props.setFixedDataGroupLayers && props.setFixedDataGroupLayers(copyFixedDataGroupLayers);
  }

  const openDropdownForPlanning = (kind: any) => {

    const tempPlanningKind = [...planningKind];
    tempPlanningKind.forEach((item: any) => {
      if (item.id == kind.id) {
        item.isOpenDropdown = !item.isOpenDropdown;
      } else{
        item.isOpenDropdown = false;
      }
    })

    const tempLayerPoints = {...props.layerGroupsPlace};
    tempLayerPoints.listGroup?.forEach((item: LayerCategoryModels.LayerCategoryModel)=>{
      item.isOpenDropdown = false;
    })

    setListGroupLayerPlace(tempLayerPoints);
    setPlanningKind(tempPlanningKind);
  }

  const openDropdownForPlace = (group : LayerCategoryModels.LayerCategoryModel) => {

    const tempLayerPoints = {...props.layerGroupsPlace};
    tempLayerPoints.listGroup?.forEach((item: LayerCategoryModels.LayerCategoryModel)=>{
      if(item.id == group.id){
        item.isOpenDropdown = !item.isOpenDropdown;
      } else {
        item.isOpenDropdown = false;
      }
    })

    const tempPlanningKind = [...planningKind];
    tempPlanningKind.forEach((item: any) => {
      item.isOpenDropdown = false;
    })
    setPlanningKind(tempPlanningKind);
    setListGroupLayerPlace(tempLayerPoints);
  }

  const closeAllDropdownEveryChip = () => {
    // const tempLayerPoints = {...props.layerGroupsPlace};
    // tempLayerPoints.listGroup?.forEach((item: LayerCategoryModels.LayerCategoryModel)=>{
    //     item.isOpenDropdown = false;
    // })

    // const tempPlanningKind = [...planningKind];
    // tempPlanningKind.forEach((item: any) => {
    //   item.isOpenDropdown = false;
    // })
    // setPlanningKind(tempPlanningKind);
    // setListGroupLayerPlace(tempLayerPoints);
  }

  console.log("check layerGroups_KHQHSDD", layerGroups_KHQHSDD);
  
  console.log("check icon", props.placeType);
  

  const renderDropdownBasedOnKind = (targetGroup: {
    'listGroup' : LayerCategoryModels.LayerCategoryModel[];
  }, kindId: number, setTargetGroup: Function) => {
    console.log("Called", targetGroup);
    
    return (<>
      {
        targetGroup.listGroup?.map((group: any)=>(
          <div key={`planningType_${group.id}`} className="list_items_wrap">
            <div className="custom-item">
              <details className="render-map-child-container pt-2 pl-2 pr-2" open>
                <summary className="mb-1" style={{minWidth:'170px'}}>
                  {group.folder_label}
                  <img
                    className="position-absolute icon-dropdow right-position"
                    src={require("../../../assets/icon/arrow-down.png")}
                    alt="Arrow Down"
                  />
                  <img
                    className="position-absolute icon-dropright right-position"
                    src={require("../../../assets/icon/arrow-right.png")}
                    alt="Arrow Right"
                  />
                </summary>
                <div>
                  {
                    group.layer_settings.map((layer : any,layerIndex: any)=>{
                      console.log(layer.is_check);
                      return (<label key={layer.geo_layer_name} className="d-block" style={{cursor:'pointer'}}>
                        <input
                          type="checkbox"
                          checked={layer.is_check}
                          className="mr-1"
                          onChange={()=>{}}
                          onClick={() => handleClickCheckboxBasedOnKind(layerIndex, !layer.is_check, group.id, targetGroup, kindId, setTargetGroup)}
                        >
                        </input>
                        <span >{layer.name}</span>
                      </label>)
                    })
                  }
                </div>
              </details>
            </div>
          </div>
        ))
      }
    </>)
  }

  function handleClickCheckboxBasedOnKind(LayerIndex: number, newValue: boolean, id: number, targetGroup: {
    'listGroup' : LayerCategoryModels.LayerCategoryModel[];
  }, kindId: number, setTargetGroup: Function) {
    let havingIsCheckPoints = false;
    const tempTargetGroup = {...targetGroup};

    tempTargetGroup.listGroup?.map((group: LayerCategoryModels.LayerCategoryModel)=>{
      if(group.id == id){
        console.log('handleClickCheckbox',group.layer_settings[LayerIndex],id);
        group.layer_settings[LayerIndex].is_check = newValue;
        // setTriggerRerender((prev)=>{return !prev})
        props.openLayerController?.toggleDisplayLayer(group.layer_settings[LayerIndex]);
        group.layer_settings.forEach((layer) => {
          if (layer.is_check) {
            havingIsCheckPoints = true;
            return;
          }
        })
      }
    })

    const tempPlanningKind = [...planningKind];
    tempPlanningKind.forEach((kind: any) => {
      if (kind.id == kindId) {
        kind.haveItemChecked = havingIsCheckPoints;
      }
    })

    setPlanningKind(tempPlanningKind);
    setTargetGroup(tempTargetGroup);
  
    // if(isMobile){
    //   props.toggleLeftNavBarAction();
    // }
  }

  const checkCanRenderThisChip = (kindId: number): boolean => {
    let result = true;
    if (kindId == 2 && props.idPlanningToHide == planningIdInfo.planningId_KHQHSDD){
      result = false;
    }else if (kindId == 3 && props.idPlanningToHide == planningIdInfo.planningId_GT){
      result = false;
    // }else if (kindId == 4 && props.idPlanningToHide == planningIdInfo.planningId_DADTXD){
    //   result = false;
    }
    
    return result;
  }

  const getIconPath = (obj: any): string => {
    let result = "";

    if(typeof(obj) == 'number') {
      if (obj == 1){
        result = iconXayDung;
      }else if (obj == 2){
        result = iconQuyHoach;
      }else if (obj == 3){
        result = iconGiaoThong;
      }else if (obj == 4){
        result = iconDuAn;
      }
    }else {
      props.placeType?.forEach((item: any) => {
        if(obj.id == item.layerCategoryId && item.iconPath) {
          result = APIUrlDefault + item.iconPath;
        }
      });
    }
    return result;
  }

  return (
    <div id="assistive-chips" className="position-absolute assistive-chips dropdown dropleft pt-1 pb-1"
      style={props.isProject ? {right: 'unset', left: 'unset'} : {}}>
      <button type="button" className="scroll_button_left" title="Button left" 
          onClick={() => {onClickScrollRightButton(); closeAllDropdownEveryChip();}}>
        <FontAwesomeIcon
          icon={faAngleLeft}
          color="#70757a"
          size="lg"
        />
      </button>
      <button type="button" className="scroll_button_right" title="Button right"
          onClick={() => {onClickScrollLeftButton(); closeAllDropdownEveryChip();}}>
        <FontAwesomeIcon
          colorInterpolation={"black"}
          icon={faAngleRight}
          color="#70757a"
          size="lg"
        />
      </button>
      <div className="position-relative content_wrap">
        <div id="chips_wrap" className="chips_wrap position-relative">
        {
            planningKind.map((kind: any, index: number)=>(
              kind.id == '1' ? (
                <RenderChip 
                  key={`chip_wrap_${kind.name}`}
                  label={kind.name}
                  isChipHaveItemChecked={getChipHaveItemChecked(kind.id)}
                  iconPath={getIconPath(kind.id)}
                  itemToBeRendered={kind}
                  openDropdownOnClick={openDropdownForPlanning}
                >
                  {
                    props.planningType.map((type: any,layerIndex: any)=>(
                      <div key={`planningType_${type.id}`} className="list_items_wrap">
                        <div className="custom-item">
                          <RenderGroupPlanning 
                            planningType={type}
                            data={getDataGroupLayers(type.id)}
                            openLayerController={props.openLayerController}
                            setFixedDataGroupLayers={setFixedDataGroupLayers}
                            haveItemChecked={getHaveItemChecked(type.id, kind.id)}
                          />
                        </div>
                      </div>
                    ))
                  }
                </RenderChip>
              )
              : 
              (
                <>
                  {
                    checkCanRenderThisChip(kind.id) &&
                    (
                      <RenderChip 
                        key={`chip_wrap_${kind.name}`}
                        label={kind.name}
                        isChipHaveItemChecked={kind.haveItemChecked}
                        iconPath={getIconPath(kind.id)}
                        itemToBeRendered={kind}
                        openDropdownOnClick={openDropdownForPlanning}
                      >
                        {
                          kind.id == 2 && props.idPlanningToHide != planningIdInfo.planningId_KHQHSDD &&
                          renderDropdownBasedOnKind(layerGroups_KHQHSDD, kind.id, setListGroupLayer_KHQHSDD)
                        }
                        {
                          kind.id == 3 && props.idPlanningToHide != planningIdInfo.planningId_GT &&
                          renderDropdownBasedOnKind(layerGroups_GT, kind.id, setListGroupLayer_GT)
                        }
                        {/* {
                          kind.id == 4 && props.idPlanningToHide != planningIdInfo.planningId_DADTXD &&
                          renderDropdownBasedOnKind(layerGroups_DADTXD, kind.id, setListGroupLayer_DADTXD)
                        } */}
                      </RenderChip>
                    )
                  }
                </>
              )
            ))
          }
          {
            layerGroupsPlace &&
            layerGroupsPlace.listGroup?.map((group: any)=>(
              <RenderChip 
                key={`chip_wrap_${group.id}`}
                label={group.folder_label}
                isChipHaveItemChecked={group.haveItemChecked}
                iconPath={getIconPath(group)}
                itemToBeRendered={group}
                openDropdownOnClick={onClickChipForPlace}
              >
                {
                  group.layer_settings.map((layer: any,layerIndex: any)=>(
                    <div key={layer.geo_layer_name} className="list_items_wrap" >
                      <div className="custom-item">
                        <label style={{cursor:'pointer'}}>
                          <input
                            type="checkbox"
                            data-select-id={group.folder_name}
                            checked={layer.is_check}
                            onChange={() => { }}
                            data-left-menu-layer-id={layer.name}
                            className="mr-1"
                            onClick={() => handleClickCheckbox(layerIndex, !layer.is_check, group.id)}
                          />
                          {layer.name}
                        </label>
                      </div>
                    </div>
                  ))
                }
              </RenderChip>
            ))
          } 
        </div>
      </div>
    </div>
  );
}

interface InitRenderGroupPlanningProps {
  planningType: any;
  data: any[];
  openLayerController: OpenlayerController;
  setFixedDataGroupLayers?: Function;
  haveItemChecked?: boolean;
}
function RenderGroupPlanning(props: InitRenderGroupPlanningProps) {
  const [triggerRerender, setTriggerRerender] = useState(false);

  const handleClickCheckbox = (item: any, newValue: any) => {
    item.isChecked = newValue;
    props.openLayerController?.addLayerManually(item.tableName, item.isAddedLayer, newValue, item.style);
    item.isAddedLayer = true;
    setTriggerRerender((prev)=>{return !prev});
    props.setFixedDataGroupLayers && props.setFixedDataGroupLayers(item);
  }
  

  return (
    <details className="render-map-child-container pt-2 pl-2 pr-2" open>
      <summary className="mb-1" style={{minWidth:'170px'}}>
        {props.planningType.name}
        <img
          className="position-absolute icon-dropdow right-position"
          src={require("../../../assets/icon/arrow-down.png")}
          alt="Arrow Down"
        />
        <img
          className="position-absolute icon-dropright right-position"
          src={require("../../../assets/icon/arrow-right.png")}
          alt="Arrow Right"
        />
      </summary>
      <div>
        {
          props.data.map((item)=>(
            <label key={`${item.kindLayer}_${props.planningType.id}`} className="d-block" style={{cursor:'pointer'}}>
              <input
                type="checkbox"
                checked={item.isChecked}
                className="mr-1"
                onChange={()=>{}}
                onClick={() => handleClickCheckbox(item,!item.isChecked)}
              >
              </input>
              <span >{item.kindLayer == "giaothong" ? "QH giao thông" : "QH sử dụng đất"}</span>
            </label>
          ))
        }
      </div>
    </details>
  );
}

interface InitRenderChipProps {
  children: any;
  itemToBeRendered: any;
  key: any;
  isChipHaveItemChecked: boolean;
  iconPath: string;
  label: string;
  openDropdownOnClick: Function;
}
function RenderChip (props: InitRenderChipProps) {
  const {
    itemToBeRendered,
    isChipHaveItemChecked,
    iconPath,
    openDropdownOnClick,
    key,
    label
  } = props;

  const customStyleWrap = {
    boxShadow: '0 1px 2px rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)',
  }

  return (
  <div className="chip_wrap" key={key}>
    <Chip
      style={isChipHaveItemChecked ? {...customStyleWrap, background:"#0077ec", color: "white"} : customStyleWrap}
      deleteIcon={<ArrowDropDown style={isChipHaveItemChecked ? {color: "white"} : {}}/>}
      onDelete={() => {openDropdownOnClick(itemToBeRendered)}} // Phải có cái này mới hiện icon
      onClick={() => {openDropdownOnClick(itemToBeRendered)}}
      label={label}
      icon={iconPath ?
        <img className={`chip_icon ${isChipHaveItemChecked ? 'have_item_checked' : 'no_item_checked'}`}
          src={iconPath}
        /> :
        <CardTravelSharp style={isChipHaveItemChecked ? {color: "white"} : {}}/>
      }
      className="chip"
    />
    <ClickOutside className="position-absolute custom-list" show={itemToBeRendered.isOpenDropdown} 
      onClickOutside={() => openDropdownOnClick(itemToBeRendered)}>
      {props.children}
    </ClickOutside>
</div>
)
}

const mapStateToProps = (state: any) => ({
  isMapToolsPanelsDisplay: state.mapToolsPanel.displayPanel,
  mapData: state.mapData.mapData,
  openLayerController: state.openlayer.openLayerController,
  listBoundaries: state.mapData.listBoundaries,
  listPolygon: state.mapData.listPolygon,
  planningType: state.statement.planningType,
  planningKind: state.statement.planningKind,
  placeType: state.statement.placeType,
});

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      SetDisplayMaptoolPanel: SetDisplayMaptoolPanel,
      SetDisplayInfomationPopup: SetDisplayInfomationPopup,
      GetAllPlanningBoundariesGeomText:MapDataStore.GetAllPlanningBoundariesGeomText,
    },
    dispatch
  );

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(connect(mapStateToProps, mapDispatchToProps)(AssistiveChipsView));
