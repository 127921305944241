import React, { useState, useEffect, useRef } from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import './preview-img-modal.scss';
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
} from "@fortawesome/free-solid-svg-icons";
import { APIUrlDefault } from "../../../utils/configuration";

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
};

function Preview(props) {
    const { imageInfo, galeryInfo, previewIndex } = props;
    
    const ref = useRef({}); 
    const sliderWrapperRef = useRef(null)

    const next = () => {
        ref.current.slickNext();
    };

    const previous = () => {
        ref.current.slickPrev();
    };

    const goToIndex = (index) => {
        if(ref.current && ref.current.slickGoTo) ref.current.slickGoTo(index);
    }
    
    useEffect(() => {
        console.log("POP UP MOUNTED");
        return () => {
            console.log("POP UP UNMOUNTED");
        }
    }, [])

    useEffect(() => {
        console.log("galeryInfo CHANGED", galeryInfo);
    }, [galeryInfo])

    useEffect(() => {
        setTimeout(()=>{
            goToIndex(previewIndex);
        }, 400)
        console.log("previewIndex CHANGED", previewIndex);
    }, [previewIndex])

    return(
        <div
            className="modal fade bd-example-modal-lg"
            role="dialog"
            id="previewImage"
            aria-hidden="true"
        >
            <div className="modal-dialog modal-lg modal-dialog-centered">
                {imageInfo && imageInfo.previewLabel && imageInfo.imageSrc && (
                    <div className="modal-content modal-content-custom">
                        <div className="preview-content-header">
                            <div className="preview-close-button" data-dismiss="modal" aria-label="Close">
                                <svg width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle"
                                     className="svg-inline--fa fa-times-circle fa-w-16" role="img"
                                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor"
                                          d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                                </svg>
                            </div>
                            <div className="preview-modal-title">{imageInfo.previewLabel}</div>
                        </div>
                        <div className="preview-content-box">
                            <img src={imageInfo.imageSrc} />
                        </div>
                    </div>
                )}
                {
                    galeryInfo && galeryInfo.previewLabel && galeryInfo.listImgGalery && (galeryInfo.showIndex !== null || galeryInfo.showIndex !== undefined) && (
                        <div className="modal-content modal-content-custom">
                            <div className="preview-content-header">
                                <div className="preview-close-button" data-dismiss="modal" aria-label="Close">
                                    <svg width="30" height="30" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle"
                                        className="svg-inline--fa fa-times-circle fa-w-16" role="img"
                                        xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                        <path fill="currentColor"
                                            d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path>
                                    </svg>
                                </div>
                                <div className="preview-modal-title">{galeryInfo.previewLabel}</div>
                            </div>
                            <div className="preview-content-box">
                                <Slider ref={ref} {...settings} initialSlide={galeryInfo.showIndex} className="image-show slick_slider_custom">
                                    {
                                        galeryInfo.listImgGalery.map((item, index) => (
                                            <div className="image-wrapper">
                                                <img key={index} src={APIUrlDefault + item}/>
                                            </div>
                                        ))
                                    }
                                </Slider>
                                <button type="button" className="scroll_button left" title="Button left" 
                                    onClick={() => {previous()}}>
                                    <FontAwesomeIcon
                                        icon={faAngleLeft}
                                        color="#70757a"
                                        size="lg"
                                    />
                                </button>
                                <button type="button" className="scroll_button right" title="Button right"
                                    onClick={() => {next()}}>
                                    <FontAwesomeIcon
                                        colorInterpolation={"black"}
                                        icon={faAngleRight}
                                        color="#70757a"
                                        size="lg"
                                    />
                                </button>
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );

}

const mapStateToProps = (state) => ({
    imageInfo: state.mapData.previewImageInfo,
    galeryInfo: state.mapData.previewGaleryInfo,
    previewIndex: state.mapData.previewIndex,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {

        },dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(Preview);