/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faTable,
  faList,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { faCopy, faMap } from "@fortawesome/free-regular-svg-icons";
import CancelPresentationIcon from "@material-ui/icons/CancelPresentation";
import { Link } from "react-router-dom";
import Select from "react-select";
import FileViewer from "../../components/file-viewer/components/file-viewer";

import * as appActions from "../../core/app.store";
import * as documentAction from "../../redux/store/document/document.store";

import RecordsManagementDetailDesktopView from "./records-management-detail/records-management-detail.view.jsx";
import Pagination from "../../components/pagination/pagination.view";

import * as config from "../../utils/configuration";
import UrlCollect from "../../common/url-collect";
import {
  NotificationMessageType,
  NotificationPosition,
} from "../../utils/configuration";
import ShowNotification from "../../components/react-notifications/react-notifications";
import * as viVN from "../../languages/vi-VN.json";

import "./records-management.scss";
import { planningIdInfo } from "../../mock-data/planningIdMock.mock";

function RecordsManagementDesktopView(props) {
  const { showLoading } = props;

  const currentPageIndex = 1;
  const currentPageSize = 8;
  const [paginationNumber, setPaginationNumber] = useState();
  const [planningModels, setPlanningModels] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [yearStatement, setYearStatement] = useState([]);
  const [documentTypeId, setDocumentTypeId] = useState();
  const [communeSearch, setCommuneSearch] = useState("");
  const [yearSearch, setYearSearch] = useState("");
  const [commune, setCommune] = useState([]);
  const [dataSearching, setDataSearching] = useState(null);
  const [planningId, setPlanningId] = useState();
  const [tabType, setTabType] = useState();
  const [isShowPreview, setShowPreview] = useState(false);
  const [file, setFile] = useState();
  const [isTable, setIsTable] = useState(true);
  const [isDetail, setIsDetail] = useState(false);

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });

  const planningIdQuery = new URLSearchParams(props.location.search).get(
    "planningId"
  );

  useEffect(() => {
    if (planningIdQuery && planningIdQuery > 0) {
      setIsDetail(true);
      setPlanningId(planningIdQuery);
      setTabType("detail");
    }

    onGetData();
  }, []);

  const onGetData = () => {
    showLoading(true);
    Promise.all([
      GetAllPlanning({
        pageIndex: currentPageIndex,
        pageSize: currentPageSize,
      }),
      GetDocumentType(),
      GetListServiceLink(),
      GetYearStatement(),
    ])
      .then((res) => {
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
        config.HandleError(err);
      });
  };

  const GetAllPlanning = (data) => {
    return new Promise((resolve, reject) => {
      documentAction
        .SearchDocument(data)
        .then((res) => {
          setPlanningModels(
            res &&
              res.content &&
              res.content.items &&
              res.content.items.length > 0
              ? res.content.items
              : []
          );
          setPaginationNumber(res.content);
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const GetDocumentType = () => {
    return new Promise((resolve, reject) => {
      documentAction
        .GetDocumentType()
        .then((res) => {
          setDocumentType(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                  return { ...item, label: item.name, value: item.id };
                })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const GetYearStatement = () => {
    return new Promise((resolve, reject) => {
      documentAction
        .GetYearStatement()
        .then((res) => {
          setYearStatement(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                  return { ...item, label: item, value: item };
                })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const GetSearchDocument = (pageIndex = 1, data = dataSearching) => {
    showLoading(true);

    documentAction
      .SearchDocument({
        pageIndex: pageIndex,
        pageSize: currentPageSize,
        name: data && data.searchFile,
        documentTypeId: documentTypeId,
        commune: communeSearch,
        year: yearSearch,
      })
      .then((res) => {
        if (
          res &&
          res.content &&
          res.content.items &&
          res.content.items.length > 0
        ) {
          setPlanningModels(res.content.items);
          setPaginationNumber(res.content);
        } else {
          ShowNotification(
            viVN.Warning.NoData,
            NotificationMessageType.Warning,
            NotificationPosition.Center
          );
        }
        showLoading(false);
      })
      .catch((err) => {
        showLoading(false);
        config.HandleError(err);
      });
  };

  const GetListServiceLink = () => {
    return new Promise((resolve, reject) => {
      documentAction
        .GetLookupCommune()
        .then((res) => {
          setCommune(
            res && res.content && res.content.length > 0
              ? res.content.map((item) => {
                  return { ...item, label: item.name, value: item.id };
                })
              : []
          );
          resolve(res);
        })
        .catch((err) => reject(err));
    });
  };

  const onBack = () => {
    setIsDetail(false);
    setShowPreview(false);
  };

  const onSubmit = (data) => {
    setIsDetail(false);
    setDataSearching(data);
    GetSearchDocument(1, data);
  };

  const handleOnchangeDocumentType = (data) => {
    setDocumentTypeId(data.value);
  };

  const handleOnChangeCommune = (data) => {
    if (data) {
      setCommuneSearch(
        data
          .map((item) => {
            return item.value;
          })
          .join(", ")
          .trim()
      );
    } else {
      setCommuneSearch("");
    }
  };

  const handleOnChangeYears = (data) => {
    if (data) {
      setYearSearch(
        data
          .map((item) => {
            return item.value;
          })
          .join(", ")
          .trim()
      );
    } else {
      setYearSearch("");
    }
  };

  console.log('planningRelationShips planningModels',planningModels);

  function getLinkBasedOnId(itemId) {
    switch (itemId) {
      case planningIdInfo.planningId_KHQHSDD:
        return `${UrlCollect.ktqhsdd}${planningIdInfo.planningId_KHQHSDD}`;
      case planningIdInfo.planningId_GT:
        return `${UrlCollect.giao_thong}${planningIdInfo.planningId_GT}`;
      // case planningIdInfo.planningId_DADTXD:
      //   // return `${UrlCollect.ProjectManagement}${planningIdInfo.planningId_DADTXD}`;
      //   return `${UrlCollect.ProjectManagement}`;
      default:
        return `${UrlCollect.PlanningMapViewDetail}${itemId}`;
    }
  }

  return (
    <div className="wrapper">
      <div className="row records-management mt-4 mb-4 ml-n2 mr-n2">
        {!isShowPreview && (
          <div id="leftPanel" className="col-12 col-md-12 col-lg-3 pl-2 pr-2">
            <div className="planning-records-search h-100">
              <div className="title-records">
                <span>
                  <FontAwesomeIcon icon={faCopy} className="mr-2" />
                  Hồ sơ quy hoạch
                </span>
              </div>
              <div className="content-records m-3">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="input-group mb-3 mt-4">
                    <input
                      type="text"
                      name="searchFile"
                      ref={register}
                      className="form-control"
                      placeholder="Tìm kiếm hồ sơ"
                      aria-label="Tìm kiếm hồ sơ"
                      aria-describedby="searchFile"
                    />
                  </div>
                  {documentType && documentType.length > 0 && (
                    <Select
                      className="basic-single mb-3 d-none"
                      classNamePrefix="select"
                      placeholder="Theo loại hồ sơ"
                      name="fileType"
                      onChange={handleOnchangeDocumentType}
                      options={documentType}
                    />
                  )}
                  {/* {commune && commune.length > 0 && (
                    <Select
                      className="basic-single mb-3"
                      classNamePrefix="select"
                      placeholder="Chọn xã/phường"
                      name="commune"
                      isMulti
                      onChange={handleOnChangeCommune}
                      options={commune}
                      noOptionsMessage={() => "Xã / Phường không tồn tại"}
                    />
                  )} */}
                  <Select
                    className="basic-single mb-3 mt-4"
                    classNamePrefix="select"
                    placeholder="Chọn năm"
                    name="year"
                    isMulti
                    onChange={handleOnChangeYears}
                    options={yearStatement}
                  />
                  <div className="input-group mb-3 mt-5">
                    <button
                      type="submit"
                      className="text-uppercase btn btn-info w-100"
                    >
                      <FontAwesomeIcon icon={faSearch} className="mr-1" />
                      Tìm kiếm
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        )}
        <div
          className={`col-12 col-md-12 ${
            isShowPreview ? "col-lg-6" : "col-lg-9"
          } pl-2 pr-2`}
        >
          <div style={{ background: "#F9F9F9" }} className="p-3">
            {!isDetail && (
              <div className="planning-records-result">
                <div className="planning-records-title border-bottom text-right mb-3">
                  <FontAwesomeIcon
                    icon={faTable}
                    className={"mr-2" + (isTable ? " active" : "")}
                    onClick={() => setIsTable(true)}
                  />
                  <FontAwesomeIcon
                    icon={faList}
                    className={!isTable ? " active" : ""}
                    onClick={() => setIsTable(false)}
                  />
                </div>
                <div
                  className={
                    "records-table" + (isTable ? " d-block" : " d-none")
                  }
                >
                  <div className="row flex-row">
                    {planningModels && planningModels.length > 0 ? (
                      planningModels.map((item, index) => (
                        <div
                          className="col-12 col-md-6 col-lg-6 mb-3"
                          key={item.id}
                        >
                          <div className="row">
                            <div className="col-4 pr-0">
                              <img
                                src={
                                  item.photo
                                    ? config.APIUrlDefault + item.photo
                                    : require("../../assets/image/img-default.png")
                                }
                                alt="demo"
                                className="img-fluid w-100"
                              />
                            </div>
                            <div className="col-8">
                              <a
                                className="title"
                                onClick={() => {
                                  setIsDetail(true);
                                  setPlanningId(item.id);
                                  setTabType("detail");
                                }}
                              >
                                {item.name}
                              </a>
                              <p className="mb-1 mt-1">
                                <b>Địa điểm:</b> {item.location}
                              </p>
                              {/* <div>
                                <FontAwesomeIcon
                                  icon={faClock}
                                  className="mr-2 icon"
                                />
                                <span>
                                  {moment(new Date(item.publishDate)).format(
                                    "DD/MM/YYYY"
                                  )}
                                </span>
                              </div> */}
                              <div>
                                <Link
                                  to={UrlCollect.RecordsManager}
                                  className="icon"
                                  onClick={() => {
                                    setIsDetail(true);
                                    setPlanningId(item.id);
                                    setTabType("detail");
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={faCopy}
                                    className="mr-2 icon"
                                  />
                                  <span>Xem hồ sơ</span>
                                </Link>

                                <a
                                  // href={UrlCollect.PlanningMap + "/" + item.id}
                                  href={`${getLinkBasedOnId(item.id)}`}
                                  target="_blank"
                                  className="ml-3"
                                >
                                  <FontAwesomeIcon
                                    icon={faMap}
                                    className="mr-2 icon"
                                  />
                                  <span>Xem bản đồ</span>
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="col-12 text-center text-danger mb-3">
                        <FontAwesomeIcon
                          icon={faExclamationTriangle}
                          className="mr-2"
                        />{" "}
                        Không có hồ sơ nào
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={
                    "records-list" + (!isTable ? " d-block" : " d-none")
                  }
                >
                  <table className="table table-striped table-responsive">
                    <tbody>
                      {planningModels && planningModels.length > 0 ? (
                        planningModels.map((item, index) => (
                          <tr key={index}>
                            <th scope="row" className="align-middle">
                              <a
                                onClick={() => {
                                  setIsDetail(true);
                                  setPlanningId(item.id);
                                  setTabType("detail");
                                }}
                              >
                                {item.name}
                              </a>
                            </th>
                            <td className="align-middle">
                              <Link
                                to={UrlCollect.RecordsManager}
                                onClick={() => {
                                  setIsDetail(true);
                                  setPlanningId(item.id);
                                  setTabType("detail");
                                }}
                                className="text-nowrap"
                              >
                                <FontAwesomeIcon
                                  icon={faCopy}
                                  className="mr-2"
                                />
                                Xem chi tiết
                              </Link>
                            </td>
                            <td className="align-middle">
                              <Link
                                to={UrlCollect.PlanningMap + "/" + item.id}
                                target="_blank"
                                className="text-nowrap"
                              >
                                <FontAwesomeIcon
                                  icon={faMap}
                                  className="mr-2"
                                />
                                Xem bản đồ
                              </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={3}>
                            <div className="text-center text-danger">
                              <FontAwesomeIcon
                                icon={faExclamationTriangle}
                                className="mr-2"
                              />{" "}
                              Không có hồ sơ nào
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
                {paginationNumber && paginationNumber.totalItemCount > 0 && (
                  <div className="row">
                    <div className="col-12 col-md-12 col-lg-12 d-none d-lg-block">
                      <div className="list-footer mb-4">
                        <div
                          className={`text-result text-center ${
                            paginationNumber.pageCount > 1 ? "float-left" : ""
                          }`}
                        >
                          <span className="ml-2">
                            Có {paginationNumber.totalItemCount} kết quả
                          </span>
                        </div>
                        {paginationNumber.pageCount > 1 && (
                          <div className="float-right">
                            <Pagination
                              totalItems={paginationNumber.totalItemCount}
                              currentIndex={paginationNumber.pageIndex}
                              pageSize={paginationNumber.pageSize}
                              onClick={GetSearchDocument}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {isDetail && (
              <RecordsManagementDetailDesktopView
                onBack={onBack}
                planningId={planningId}
                tabType={tabType}
                isShowPreview={isShowPreview}
                setShowPreview={setShowPreview}
                setFile={setFile}
                setPlanningId={setPlanningId}
              />
            )}
          </div>
        </div>

        {isShowPreview && (
          <div
            className={`col-12 col-md-12 col-lg-6 pl-2 pr-2`}
            style={{ height: window.innerHeight }}
          >
            {file ? (
              <FileViewer
                key={file.id}
                fileType={file.extension.toLowerCase()}
                filePath={config.APIUrlDefault + file.path}
                errorComponent={
                  <div className="mt-4 d-flex align-items-center justify-content-center">
                    <CancelPresentationIcon className="text-danger mr-1" /> Lỗi
                    đọc file
                  </div>
                }
                onError={() => console.log("err")}
              />
            ) : (
              <div className="mt-4 d-flex align-items-center justify-content-center">
                <CancelPresentationIcon className="text-danger mr-1" /> Không có
                file để hiển thị
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RecordsManagementDesktopView);
