import React, { useEffect, useState, useContext } from "react";

export const GetDataProject = React.createContext();
export const SetDataProject = React.createContext();

export function DataProjectProvider({children}) {
    const [dataProject, setDataProject] = useState();

    return (
        <GetDataProject.Provider value={dataProject}>
            <SetDataProject.Provider value={setDataProject}>
                {children}
            </SetDataProject.Provider>
        </GetDataProject.Provider>
    )
}

export function useGetDataProject() {
    return useContext(GetDataProject);
}

export function useSetDataProject() {
    return useContext(SetDataProject);
}