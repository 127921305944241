import React from 'react';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <>
        <img
            src={require("./arrow-left.png")}
            alt="left"
            {...props}
            className={
                'slick-prev slick-arrow' + (currentSlide === slideCount + 1 ? ' slick-disabled' : '')
            }
            aria-hidden="true"
            aria-disabled={currentSlide === 0 ? true : false}
        />
    </>
)

export default SlickArrowLeft;