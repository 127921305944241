import React, { Fragment, useState, useContext } from "react";

const GetIsAddedSearchBoxContext = React.createContext();
const SetIsAddedSearchBoxContext = React.createContext();
const GetIsAddedMapToolsContext = React.createContext();
const SetIsAddedMapToolsContext = React.createContext();
const GetIsAddedPlanningInfoContext = React.createContext();
const SetIsAddedPlanningInfoContext = React.createContext();
const GetIsAddedLayerInfoContext = React.createContext();
const SetIsAddedLayerInfoContext = React.createContext();

const ToggleDisplayContext = React.createContext();

export function AddContentBottomPanelProvider({children}) {
    const [isAddedSearchBox, setIsAddedSearchBox] = useState(false);
    const [isAddedMapTools, setIsAddedMapTools] = useState(false);
    const [isAddedPlanningInfo, setIsAddedPlanningInfo] = useState(false);
    const [isAddedLayerInfo, setIsAddedLayerInfo] = useState(false);
    
    const toggleDisplay = (targetId) => {
        console.log('toggleDisplaytoggleDisplaytoggleDisplaytoggleDisplay');
        const bottomPanelContent = document.getElementById('bottom-panel_content');
        if (bottomPanelContent) { 
        const children = bottomPanelContent.children;
    
        for (let i = 0; i < children.length; i++) {
            const child = children[i];
            if (child.id === targetId && child.style.display !== 'none') {
                return;
            }
        }

        if(!targetId) {
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                child.style.display = 'none';
            }     
        } else {
            for (let i = 0; i < children.length; i++) {
                const child = children[i];
                if(child.id === targetId) {
                    child.style.display = '';
                }
                else {
                    child.style.display = 'none';
                }
            }   
        }
        }
    };

    return (
        <GetIsAddedSearchBoxContext.Provider value={isAddedSearchBox}>
            <SetIsAddedSearchBoxContext.Provider value={setIsAddedSearchBox}>
                <GetIsAddedMapToolsContext.Provider value={isAddedMapTools}>
                    <SetIsAddedMapToolsContext.Provider value={setIsAddedMapTools}>
                        <GetIsAddedPlanningInfoContext.Provider value={isAddedPlanningInfo}>
                            <SetIsAddedPlanningInfoContext.Provider value={setIsAddedPlanningInfo}>
                                <GetIsAddedLayerInfoContext.Provider value={isAddedLayerInfo}>
                                    <SetIsAddedLayerInfoContext.Provider value={setIsAddedLayerInfo}>
                                        <ToggleDisplayContext.Provider value={toggleDisplay}>
                                            {children}
                                        </ToggleDisplayContext.Provider>
                                    </SetIsAddedLayerInfoContext.Provider>
                                </GetIsAddedLayerInfoContext.Provider>
                            </SetIsAddedPlanningInfoContext.Provider>
                        </GetIsAddedPlanningInfoContext.Provider>
                    </SetIsAddedMapToolsContext.Provider>
                </GetIsAddedMapToolsContext.Provider>
            </SetIsAddedSearchBoxContext.Provider>
        </GetIsAddedSearchBoxContext.Provider>
    )
}

export function useGetIsAddedSearchBox() {
    return useContext(GetIsAddedSearchBoxContext);
}

export function useSetIsAddedSearchBox() {
    return useContext(SetIsAddedSearchBoxContext);
}

export function useGetIsAddedMapTools() {
    return useContext(GetIsAddedMapToolsContext);
}

export function useSetIsAddedMapTools() {
    return useContext(SetIsAddedMapToolsContext);
}

export function useGetIsAddedPlanningInfo() {
    return useContext(GetIsAddedPlanningInfoContext);
}

export function useSetIsAddedPlanningInfo() {
    return useContext(SetIsAddedPlanningInfoContext);
}

export function useGetIsAddedLayerInfo() {
    return useContext(GetIsAddedLayerInfoContext);
}

export function useSetIsAddedLayerInfo() {
    return useContext(SetIsAddedLayerInfoContext);
}

export function useToggleDisplay() {
    return useContext(ToggleDisplayContext);
}
