import React, { useEffect } from 'react';
import './layout-map-page.scss';
import HeaderView from '../header/header.view.jsx';
import LoadingWithApiQueue from '../loading/loading-with-queue.view';
import TopView from '../top/top.view';

function LayoutMapPageView(props) {

    return (
        <div className="layout-map-page-container">
            <TopView />
            <HeaderView match={props.match} />
            <div>
                {props.children}
            </div>
            <LoadingWithApiQueue />
        </div>
    )
}

export default LayoutMapPageView;
  
  